<template>
  <Schema>
    <form class="w-full max-w-3xl">
      <!-- Breadcrumb -->
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <span class="text-blue-500"> • Solicitação</span>
        </div>
      </div>

      <div class="flex flex-col">
        <h1 class="text-3xl text-gray-600">Criar uma solicitação</h1>
        <p class="text-gray-400 max-w-xs mt-1">
          Solicite uma reserva para as áreas do Albert Einstein
        </p>
      </div>

      <!-- Step one -->
      <div v-show="step === 1">
        <StepOne />
      </div>

      <div v-show="step === 2">
        <StepTwo />
      </div>

      <div v-show="step === 3">
        <StepThree />
      </div>

      <!-- Step 2.1 por data -->
      <div v-show="step === 2.1">
        <h1 class="mt-14 bg-blue-500 text-white px-5 py-2 rounded-lg text-sm">
          • Solicitação por data
        </h1>

        <section class="mt-14">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Unidade

              <select
                v-model="unitId"
                @change="getLocalWithUnit"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option :value="null" selected disabled>
                  Selecione uma unidade
                </option>
                <option
                  v-for="item in unitsData"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Local

              <select
                :disabled="unitId === ''"
                @change="getDataForLocalSelected"
                v-model="localId"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option value="" selected disabled>Selecione um local</option>
                <option v-for="item in locals" :key="item.id" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              <div class="flex items-center justify-between">
                Data

                <div class="group relative cursor-help">
                  <i class="ph ph-info text-xl"></i>

                  <div
                    class="absolute right-2 bottom-6 bg-gray-500 w-[200px] shadow-lg z-40 rounded-lg text-white p-2 text-center transition-all scale-0 origin-bottom-right duration-300 group-hover:scale-100"
                  >
                    Em caso de recorrência de data, informar as datas
                  </div>
                </div>
              </div>

              <Calendar
                v-model="date"
                showIcon
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                dateFormat="dd/mm/yy"
                :manualInput="false"
                @date-select="getNameDayOfWeekByDate"
                :disabled="localId === ''"
              />
            </label>
          </div>
        </section>

        <section class="mt-4 flex items-center gap-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              <div class="flex items-center justify-between">
                Horário inicial

                <div class="group relative cursor-help">
                  <i class="ph ph-info text-xl"></i>

                  <div
                    class="absolute right-2 bottom-6 bg-gray-500 w-[200px] shadow-lg z-40 rounded-lg text-white p-2 text-center transition-all scale-0 origin-bottom-right duration-300 group-hover:scale-100"
                  >
                    Insira o horário de início do evento e/ou da transmissão. O
                    espaço ou link ficará à disposição com 1 hora de
                    antecedência.
                  </div>
                </div>
              </div>

              <input
                type="time"
                v-model="startTime"
                :disabled="date === ''"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>

          <div class="flex items-end gap-2 relative">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Horário final

                <input
                  type="time"
                  v-model="finalTime"
                  :disabled="startTime === ''"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>

              <div
                :class="`absolute left-0 top-24 z-40 bg-gray-600 py-4 px-4 rounded-lg w-full transition-all duration-500 ${
                  isActiveLocalError
                    ? 'opacity-100 visible'
                    : 'opacity-0 invisible'
                }`"
              >
                <button
                  type="button"
                  class="text-white w-6 h-6 hover:brightness-90 transition-all flex items-center justify-center absolute -left-2 -top-2 bg-gray-500 rounded-full"
                  @click="isActiveLocalError = !isActiveLocalError"
                >
                  <i class="ph ph-x"></i>
                </button>

                <div
                  class="flex items-center justify-between border-b border-gray-500 pb-2"
                >
                  <h1 class="text-white text-sm">Horários de funcionamento</h1>

                  <span
                    class="bg-gray-500 text-white text-xs px-3 py-1 rounded-full"
                    >{{ localsError.length }} horários</span
                  >
                </div>

                <div
                  v-for="local in localsError"
                  :key="local.id"
                  class="flex items-center shadow-lg justify-between bg-gray-500 mt-4 text-white rounded-lg text-sm px-4 py-1"
                >
                  <h1>{{ local.workingTimeDay }}</h1>
                  <span>{{ local.initialTime }} - {{ local.endTime }}</span>
                </div>

                <span
                  class="block w-3 h-3 bg-gray-600 absolute right-3 -top-1 rotate-45"
                ></span>
              </div>
            </div>
            <!-- <button
              type="button"
              @click="handleGetDisponibilityData"
              class="bg-blue-500 h-12 text-white rounded-lg text-xs px-3 hover:brightness-90 transition-all"
            >
              Verificar disponibilidade
            </button> -->
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Tornar recorrente

              <select
                v-model="recurrent"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option value="" disabled selected>Selecione uma opção</option>
                <option :value="true">Sim</option>
                <option :value="false">Não</option>
              </select>
            </label>
          </div>
        </section>

        <RecurrentSolicitation
          v-show="recurrent"
          :recurrentSolicitationDataForm="recurrentSolicitationDataForm"
          @getNameDayOfWeekByDate="getNameDayOfWeekByDate"
        />

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Centro de custo

              <input
                type="text"
                v-model="costCenter"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Gerência

              <input
                type="text"
                v-model="manager"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Diretoria

              <input
                type="text"
                v-model="board"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-6 flex justify-between items-center">
          <button
            @click="handleNextStep(1)"
            type="button"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Voltar</span>
          </button>

          <button
            @click="
              handleNextStep(localNameStepRoom === 'Auditórios' ? 3.1 : 3.2)
            "
            :disabled="
              localNameStepRoom === '' ||
              date === '' ||
              startTime === '' ||
              finalTime === '' ||
              costCenter === '' ||
              manager === '' ||
              board === '' ||
              (recurrent &&
                (recurrentSolicitationDataForm.defaultRecurrent === '' ||
                  recurrentSolicitationDataForm.endDate === ''))
            "
            type="button"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Continuar</span>
          </button>
        </section>
      </div>

      <!-- Step 2.2 por sala/auditório -->
      <div v-show="step === 2.2">
        <h1 class="mt-14 bg-blue-500 text-white px-5 py-2 rounded-lg text-sm">
          • Solicitação por Sala/Auditórios
        </h1>

        <section class="mt-14">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Unidade

              <select
                v-model="unitId"
                @change="handleGetLocalsWithUnit"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option :value="null" selected disabled>
                  Todas as unidades
                </option>
                <option
                  v-for="unit in unitsData"
                  :key="unit.id"
                  :value="unit.id"
                >
                  {{ unit.name }}
                </option>
              </select>
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Local

              <select
                v-model="localId"
                @change="handleGetRoomsWithLocalId"
                :disabled="unitId === null"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option value="" selected disabled>Todos os locais</option>
                <option
                  v-for="local in localsSetWithUnitSelected"
                  :key="local.id"
                  :value="local.id"
                >
                  {{ local.name }}
                </option>
              </select>
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Salas/Auditórios

              <select
                v-model="roomId"
                @change="handleGetLocalAndUnit"
                :disabled="localId === ''"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option :value="null" selected disabled>Todas as salas</option>
                <option
                  v-for="room in roomsSetWithLocalSelected"
                  :key="room.id"
                  :value="room.id"
                  v-show="room.maintenance === false || room.occult === false"
                >
                  {{ room.name }}
                </option>
              </select>
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              <div class="flex items-center justify-between">
                Data

                <div class="group relative cursor-help">
                  <i class="ph ph-info text-xl"></i>

                  <div
                    class="absolute right-2 bottom-6 bg-gray-500 w-[200px] shadow-lg z-40 rounded-lg text-white p-2 text-center transition-all scale-0 origin-bottom-right duration-300 group-hover:scale-100"
                  >
                    Em caso de recorrência de data, informar as datas
                  </div>
                </div>
              </div>

              <Calendar
                v-model="date"
                showIcon
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                dateFormat="dd/mm/yy"
                :manualInput="false"
                :disabled="localId === ''"
              />
            </label>
          </div>
        </section>

        <section class="mt-4 flex items-center gap-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              <div class="flex items-center justify-between">
                Horário inicial

                <div class="group relative cursor-help">
                  <i class="ph ph-info text-xl"></i>

                  <div
                    class="absolute right-2 bottom-6 bg-gray-500 w-[200px] shadow-lg z-40 rounded-lg text-white p-2 text-center transition-all scale-0 origin-bottom-right duration-300 group-hover:scale-100"
                  >
                    Insira o horário de início do evento e/ou da transmissão. O
                    espaço ou link ficará à disposição com 1 hora de
                    antecedência.
                  </div>
                </div>
              </div>

              <input
                type="time"
                v-model="startTime"
                :disabled="date === ''"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>

          <div class="flex items-end gap-2 relative">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Horário final

                <input
                  type="time"
                  v-model="finalTime"
                  :disabled="startTime === ''"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>

              <div
                :class="`absolute left-0 top-24 z-40 bg-blue-500 py-4 px-4 rounded-lg w-full transition-all duration-500 ${
                  isActiveLocalError
                    ? 'opacity-100 visible'
                    : 'opacity-0 invisible'
                }`"
              >
                <button
                  type="button"
                  class="text-white w-6 h-6 hover:brightness-90 transition-all flex items-center justify-center absolute -left-2 -top-2 bg-gray-500 rounded-full"
                  @click="isActiveLocalError = !isActiveLocalError"
                >
                  <i class="ph ph-x"></i>
                </button>

                <div
                  class="flex items-center justify-between border-b border-gray-500 pb-2"
                >
                  <h1 class="text-white text-sm">Agendamentos do dia</h1>

                  <span
                    class="bg-gray-500 text-white text-xs px-3 py-1 rounded-full"
                    >{{ roomsAppointments.length }} horários</span
                  >
                </div>

                <div
                  v-for="room in roomsAppointments"
                  :key="room.nome"
                  class="flex items-center shadow-lg justify-between bg-gray-500 mt-4 text-white rounded-lg text-sm px-4 py-1"
                >
                  <span>{{ room.startTime }} - {{ room.finalTime }}</span>
                </div>

                <span
                  class="block w-3 h-3 bg-blue-500 absolute right-3 -top-1 rotate-45"
                ></span>
              </div>
            </div>

            <button
              type="button"
              @click="handleGetDisponibilityAudit"
              class="bg-blue-500 h-12 text-white rounded-lg text-xs px-3 hover:brightness-90 transition-all"
            >
              Verificar disponibilidade
            </button>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Tornar recorrente

              <select
                v-model="recurrent"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option value="" disabled selected>Selecione uma opção</option>
                <option :value="true">Sim</option>
                <option :value="false">Não</option>
              </select>
            </label>
          </div>
        </section>

        <RecurrentSolicitation
          v-show="recurrent"
          :recurrentSolicitationDataForm="recurrentSolicitationDataForm"
          @getNameDayOfWeekByDate="getNameDayOfWeekByDate"
        />

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Centro de custo

              <input
                type="text"
                v-model="costCenter"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Gerência

              <input
                type="text"
                v-model="manager"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Diretoria

              <input
                type="text"
                v-model="board"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-6 flex justify-between items-center">
          <button
            @click="handleNextStep(1)"
            type="button"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Voltar</span>
          </button>

          <button
            @click="
              handleNextStep(localNameStepRoom === 'Auditórios' ? 3.1 : 3.2)
            "
            :disabled="
              localNameStepRoom === '' ||
              date === '' ||
              startTime === '' ||
              finalTime === '' ||
              costCenter === '' ||
              manager === '' ||
              board === '' ||
              isBlockedButtonNext === true ||
              isBlockedButtonNextLocal === true ||
              (recurrent &&
                (recurrentSolicitationDataForm.defaultRecurrent === '' ||
                  recurrentSolicitationDataForm.endDate === ''))
            "
            type="button"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Continuar</span>
          </button>
        </section>
      </div>

      <!-- ================== OK ================= -->

      <!-- Step 3.1 por auditório selecionar -->
      <div v-show="step === 3.1">
        <h1 class="mt-14 bg-blue-500 text-white px-5 py-2 rounded-lg text-sm">
          • Auditório
        </h1>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              <div class="flex items-center justify-between">
                Tipo de sala

                <div
                  v-show="roomTypeAuditorium === 'Híbrido'"
                  class="group relative cursor-help"
                >
                  <i class="ph ph-info text-xl"></i>

                  <div
                    class="absolute right-2 bottom-6 bg-gray-500 w-[200px] shadow-lg z-40 rounded-lg text-white p-2 text-center transition-all scale-0 origin-bottom-right duration-300 group-hover:scale-100"
                  >
                    Agendamento com transmissão e com público e/ou palestrante
                    presencial nos auditórios.
                  </div>
                </div>
              </div>

              <select
                v-if="solicitationType !== 'Solicitação por data'"
                v-model="roomTypeAuditorium"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-sm mt-2 pl-4"
              >
                <option value="" selected disabled>
                  Selecione o tipo de sala
                </option>
                <option
                  v-show="
                    roomTypeByRoomSelect === 'On-line' ||
                    roomTypeByRoomSelect === 'Híbrido'
                  "
                  value="On-line"
                >
                  On-line
                </option>
                <option
                  v-show="
                    roomTypeByRoomSelect === 'Presencial' ||
                    roomTypeByRoomSelect === 'Híbrido'
                  "
                  value="Presencial"
                >
                  Presencial
                </option>
                <option
                  v-show="roomTypeByRoomSelect === 'Híbrido'"
                  value="Híbrido"
                >
                  Híbrido
                </option>
              </select>

              <select
                v-else
                v-model="roomTypeAuditorium"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option value="" selected disabled>
                  Selecione o tipo de sala
                </option>
                <option value="On-line">On-line</option>
                <option value="Presencial">Presencial</option>
                <option value="Híbrido">Híbrido</option>
              </select>
            </label>
          </div>
        </section>

        <section class="mt-6 flex justify-between items-center">
          <button
            @click="
              handleNextStep(
                solicitationType === 'Solicitação por data' ? 2.1 : 2.2
              )
            "
            type="button"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Voltar</span>
          </button>

          <button
            @click="
              handleNextStep(
                roomTypeAuditorium === 'On-line'
                  ? 4.1
                  : roomTypeAuditorium === 'Presencial'
                  ? 4.2
                  : roomTypeAuditorium === 'Híbrido' && 4.3
              )
            "
            type="button"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Continuar</span>
          </button>
        </section>
      </div>

      <!-- Step 3.2 por sala selecionar -->
      <div v-show="step === 3.2">
        <h1 class="mt-14 bg-blue-500 text-white px-5 py-2 rounded-lg text-sm">
          • Sala
        </h1>

        <select
          v-if="solicitationType !== 'Solicitação por data'"
          v-model="roomType"
          class="border-2 border-gray-300 h-12 rounded-lg w-full text-sm mt-2 pl-4 text-gray-500"
        >
          <option value="" selected disabled>Selecione o tipo de sala</option>
          <option
            v-show="
              roomTypeByRoomSelect === 'On-line' ||
              roomTypeByRoomSelect === 'Híbrido'
            "
            value="On-line"
          >
            On-line
          </option>
          <option
            v-show="
              roomTypeByRoomSelect === 'Presencial' ||
              roomTypeByRoomSelect === 'Híbrido'
            "
            value="Presencial"
          >
            Presencial
          </option>
          <option v-show="roomTypeByRoomSelect === 'Híbrido'" value="Híbrido">
            Híbrido
          </option>
        </select>

        <section v-else class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Tipo de sala

              <select
                v-model="roomType"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option value="" disabled selected>
                  Selecione o tipo de sala
                </option>
                <option value="On-line">On-line</option>
                <option value="Presencial">Presencial</option>
                <option value="Híbrido">Híbrido</option>
              </select>
            </label>
          </div>
        </section>

        <section class="mt-6 flex justify-between items-center">
          <button
            @click="
              handleNextStep(
                solicitationType === 'Solicitação por data' ? 2.1 : 2.2
              )
            "
            type="button"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Voltar</span>
          </button>

          <button
            @click="
              handleNextStep(
                roomType === 'On-line'
                  ? 5.1
                  : roomType === 'Presencial'
                  ? 5.2
                  : roomType === 'Híbrido' && 5.3
              )
            "
            :disabled="roomType === ''"
            type="button"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Continuar</span>
          </button>
        </section>
      </div>

      <!-- ================== OK ================= -->

      <!-- Step online auditorio -->
      <div v-show="step === 4.1">
        <h1 class="mt-14 bg-blue-500 text-white px-5 py-2 rounded-lg text-sm">
          • On-line por auditório
        </h1>

        <section class="mt-14">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Nome do evento/curso

              <input
                type="text"
                v-model="eventName"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Descrição do evento/curso

              <input
                type="text"
                v-model="eventDescription"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <div>
          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                <div class="flex items-center justify-between">
                  Tipo de transmissão

                  <div
                    v-show="typeOfTransmission !== ''"
                    class="group relative cursor-help"
                  >
                    <i class="ph ph-info text-xl"></i>

                    <div
                      class="absolute right-2 bottom-6 bg-gray-500 w-[200px] shadow-lg z-40 rounded-lg text-white p-2 text-center transition-all scale-0 origin-bottom-right duration-300 group-hover:scale-100"
                    >
                      {{
                        typeOfTransmission === 'Zoom'
                          ? 'Plataforma de transmissão- Até 3.000 participantes'
                          : typeOfTransmission === 'Workplace'
                          ? 'Transmissão na plataforma de comunicação interna. Informar o grupo que será utilizado. Sem limite de participantes.'
                          : 'Transmissão na plataforma. Informar a conta que será utilizada. Sem limite de participantes.'
                      }}
                    </div>
                  </div>
                </div>

                <select
                  v-model="typeOfTransmission"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                >
                  <option value="" selected disabled>
                    Selecione uma opção
                  </option>
                  <option value="Zoom">Zoom</option>
                  <option value="Workplace">Workplace</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Youtube">Youtube</option>
                </select>
              </label>
            </div>
          </section>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Workplace, Facebook e Youtube

                <input
                  type="text"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>
            </div>
          </section>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Trilha sonora para o início da live

                <div
                  class="w-full border-dashed border border-gray-300 mt-2 py-4 flex items-center justify-center rounded-lg hover:border-blue-500 cursor-pointer"
                >
                  <i class="ph ph-file-audio"></i>
                </div>

                <input
                  type="file"
                  @change="handleGetFileBufferAudioFile"
                  hidden
                />
              </label>

              <span class="block mt-2 text-sm text-gray-500">{{
                selectedFileAudioName
              }}</span>
            </div>
          </section>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Texto para rede social

                <input
                  v-model="socialNetworkText"
                  type="text"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>
            </div>
          </section>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                <div class="flex items-center justify-between">
                  Tipo de link

                  <div
                    v-show="typeOfLink !== ''"
                    class="group relative cursor-help"
                  >
                    <i class="ph ph-info text-xl"></i>

                    <div
                      class="absolute right-2 bottom-6 bg-gray-500 w-[200px] shadow-lg z-40 rounded-lg text-white p-2 text-center transition-all scale-0 origin-bottom-right duration-300 group-hover:scale-100"
                    >
                      {{
                        typeOfLink === 'Reunião'
                          ? 'Todos os participantes podem ter acesso à câmera e microfone. Limite- 300 participantes'
                          : 'Apenas palestrantes com acesso à câmera. Demais participantes interagem pelo chat. Limite- 3.000 participantes'
                      }}
                    </div>
                  </div>
                </div>

                <select
                  v-model="typeOfLink"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                >
                  <option value="" selected disabled>
                    Selecione uma opção
                  </option>
                  <option value="Webinar">
                    Webinar (até 1.000 ou 3.000 participantes, apenas
                    palestrante tem acesso à câmera)
                  </option>
                  <option value="Reunião">
                    Reunião (até 300 participantes, todos acessam possuem acesso
                    à câmera)
                  </option>
                </select>
              </label>
            </div>
          </section>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Recursos do zoom

                <select
                  v-model="zoomResources"
                  multiple
                  class="border-2 border-gray-300 h-26 rounded-lg w-full text-md mt-2 p-4"
                >
                  <option value="Ficha de inscrição">Ficha de inscrição</option>
                  <option value="Enquete">Enquete</option>
                  <option value="Q&A">Q&A</option>
                </select>
              </label>
            </div>
          </section>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Previsão de acessos

                <input
                  type="text"
                  v-model="accessesPrevision"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>
            </div>
          </section>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Anexar thumbnail

                <div
                  class="w-full border-dashed border border-gray-300 mt-2 py-4 flex items-center justify-center rounded-lg hover:border-blue-500 cursor-pointer"
                >
                  <i class="ph ph-plus"></i>
                </div>

                <input
                  type="file"
                  @change="handleGetFileBufferThumbnail"
                  hidden
                />
              </label>

              <span class="block mt-2 text-sm text-gray-500">{{
                selectedFileThumbnailName
              }}</span>
            </div>
          </section>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Programação

                <div class="flex items-end gap-2">
                  <input
                    type="text"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />

                  <span class="block mt-2 text-sm text-gray-500">{{
                    selectedFileProgrammingName
                  }}</span>

                  <label>
                    <div
                      class="w-12 h-12 border-dashed border border-gray-300 rounded-lg flex items-center text-gray-400 justify-center hover:border-blue-500 hover:text-blue-500 transition-all cursor-pointer"
                    >
                      <i class="ph ph-plus"></i>
                    </div>

                    <input
                      type="file"
                      @change="handleGetFileBufferProgramming"
                      hidden
                    />
                  </label>
                </div>
              </label>
            </div>
          </section>
        </div>

        <section class="mt-6 flex justify-between items-center">
          <button
            @click="
              handleNextStep(localNameStepRoom === 'Auditórios' ? 3.1 : 3.2)
            "
            type="button"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Voltar</span>
          </button>

          <button
            type="button"
            :disabled="
              eventName === '' ||
              eventDescription === '' ||
              typeOfLink === '' ||
              zoomResources === '' ||
              accessesPrevision === '' ||
              isLoadingSolicitation
            "
            @click="handleCreateSolicitation"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <div
              v-if="isLoadingSolicitation"
              class="block w-5 h-5 rounded-full border border-gray-400 animate-spin border-t-blue-500"
            ></div>

            <span v-else> Salvar </span>
          </button>
        </section>
      </div>

      <!-- Step presencial auditorio -->
      <div v-show="step === 4.2">
        <h1 class="mt-14 bg-blue-500 text-white px-5 py-2 rounded-lg text-sm">
          • Presencial por auditório
        </h1>

        <section class="mt-14">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Nome do evento/curso

              <input
                type="text"
                v-model="eventName"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Descrição do evento/curso

              <input
                type="text"
                v-model="eventDescription"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section
          class="mt-4"
          v-if="solicitationType === 'Solicitação por data'"
        >
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Auditórios

              <select
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                v-model="roomId"
              >
                <option value="">Selecione uma opção</option>
                <option
                  v-for="item in auditorys"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Número de participantes

              <input
                type="number"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                v-model="numberOfParticipants"
              />
            </label>
          </div>
        </section>

        <div>
          <EquipmentSelection
            :equipments="equipments"
            :equipmentsSelect="equipmentsSelect"
            :handleAddEquipment="handleAddEquipment"
            :handleRemoveEquipment="handleRemoveEquipment"
          />
        </div>

        <section class="mt-4">
          <label class="text-sm text-gray-500">
            Observação equipamentos

            <textarea
              v-model="equipmentsDescription"
              class="border-2 border-gray-300 min-h-[120px] resize-y rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </section>

        <section class="mt-4" v-show="!recurrent">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Exige preparação prévia

              <select
                v-model="previousPreparation"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option value="">Selecione uma opção</option>
                <option :value="true">Sim</option>
                <option :value="false">Não</option>
              </select>
            </label>
          </div>
        </section>

        <div v-show="previousPreparation">
          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Data de início montagem

                <input
                  v-model="preparationStartDay"
                  type="date"
                  class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>
            </div>
          </section>

          <div class="flex items-center gap-4">
            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário início montagem

                  <input
                    v-model="preparationStartInitial"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>

            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário final montagem

                  <input
                    v-model="preparationStartFinal"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>
          </div>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Data desmontagem

                <input
                  v-model="preparationEndDay"
                  type="date"
                  class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>
            </div>
          </section>

          <div class="flex items-center gap-4">
            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário início desmontagem

                  <input
                    v-model="preparationEndInitial"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>

            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário final desmontagem

                  <input
                    v-model="preparationEndFinal"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>
          </div>
        </div>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Programação

              <div class="flex items-end gap-2">
                <input
                  type="text"
                  class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />

                <label>
                  <div
                    class="w-12 h-12 border-dashed border border-gray-300 rounded-lg flex items-center text-gray-400 justify-center hover:border-blue-500 hover:text-blue-500 transition-all cursor-pointer"
                  >
                    <i class="ph ph-plus"></i>
                  </div>

                  <input type="file" hidden />
                </label>
              </div>
            </label>
          </div>
        </section>

        <section class="mt-6 flex justify-between items-center">
          <button
            @click="
              handleNextStep(localNameStepRoom === 'Auditórios' ? 3.1 : 3.2)
            "
            type="button"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Voltar</span>
          </button>

          <button
            type="button"
            @click="handleCreateSolicitation"
            :disabled="isLoadingSolicitation"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <div
              v-if="isLoadingSolicitation"
              class="block w-5 h-5 rounded-full border border-gray-400 animate-spin border-t-blue-500"
            ></div>

            <span v-else> Salvar </span>
          </button>
        </section>
      </div>

      <!-- Step hibrido auditorio -->
      <div v-show="step === 4.3">
        <h1 class="mt-14 bg-blue-500 text-white px-5 py-2 rounded-lg text-sm">
          • Híbrido por auditório
        </h1>

        <!-- OK -->
        <section class="mt-14">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Nome do evento/curso

              <input
                type="text"
                v-model="eventName"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section
          class="mt-4"
          v-if="solicitationType === 'Solicitação por data'"
        >
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Auditórios

              <select
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                v-model="roomId"
              >
                <option value="">Selecione uma opção</option>
                <option
                  v-for="item in auditorys"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </label>
          </div>
        </section>

        <!-- OK -->
        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Descrição do evento/curso

              <input
                type="text"
                v-model="eventDescription"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <!-- <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Auditórios

              <select class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4">
                <option value="">Selecione uma opção</option>
                <option 
                  v-for="item in auditorys"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </label>
          </div>
        </section> -->

        <!-- OK -->
        <div>
          <!-- OK -->
          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                <div class="flex items-center justify-between">
                  Tipo de transmissão

                  <div
                    v-show="typeOfTransmission !== ''"
                    class="group relative cursor-help"
                  >
                    <i class="ph ph-info text-xl"></i>

                    <div
                      class="absolute right-2 bottom-6 bg-gray-500 w-[200px] shadow-lg z-40 rounded-lg text-white p-2 text-center transition-all scale-0 origin-bottom-right duration-300 group-hover:scale-100"
                    >
                      {{
                        typeOfTransmission === 'Zoom'
                          ? 'Plataforma de transmissão- Até 3.000 participantes'
                          : typeOfTransmission === 'Workplace'
                          ? 'Transmissão na plataforma de comunicação interna. Informar o grupo que será utilizado. Sem limite de participantes.'
                          : 'Transmissão na plataforma. Informar a conta que será utilizada. Sem limite de participantes.'
                      }}
                    </div>
                  </div>
                </div>

                <select
                  v-model="typeOfTransmission"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                >
                  <option value="" selected disabled>
                    Selecione uma opção
                  </option>
                  <option value="Zoom">Zoom</option>
                  <option value="Workplace">Workplace</option>
                  <option value="Facebook">Facebook</option>
                  <option value="Youtube">Youtube</option>
                </select>
              </label>
            </div>
          </section>

          <!-- OK -->
          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Workplace, Facebook e Youtube

                <input
                  type="text"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>
            </div>
          </section>

          <!-- OK -->
          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Trilha sonora para o início da live

                <div
                  class="w-full border-dashed border border-gray-300 mt-2 py-4 flex items-center justify-center rounded-lg hover:border-blue-500 cursor-pointer"
                >
                  <i class="ph ph-file-audio"></i>
                </div>

                <input
                  type="file"
                  @change="handleGetFileBufferAudioFile"
                  hidden
                />
              </label>
            </div>
          </section>

          <!-- OK -->
          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                <div class="flex items-center justify-between">
                  Tipo de link

                  <div
                    v-show="typeOfLink !== ''"
                    class="group relative cursor-help"
                  >
                    <i class="ph ph-info text-xl"></i>

                    <div
                      class="absolute right-2 bottom-6 bg-gray-500 w-[200px] shadow-lg z-40 rounded-lg text-white p-2 text-center transition-all scale-0 origin-bottom-right duration-300 group-hover:scale-100"
                    >
                      {{
                        typeOfLink === 'Reunião'
                          ? 'Todos os participantes podem ter acesso à câmera e microfone. Limite- 300 participantes'
                          : 'Apenas palestrantes com acesso à câmera. Demais participantes interagem pelo chat. Limite- 3.000 participantes'
                      }}
                    </div>
                  </div>
                </div>

                <select
                  v-model="typeOfLink"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                >
                  <option value="" selected disabled>
                    Selecione uma opção
                  </option>
                  <option value="Webinar">
                    Webinar (até 1.000 ou 3.000 participantes, apenas
                    palestrante tem acesso à câmera)
                  </option>
                  <option value="Reunião">
                    Reunião (até 300 participantes, todos acessam possuem acesso
                    à câmera)
                  </option>
                </select>
              </label>
            </div>
          </section>

          <!-- OK -->
          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Recursos do zoom

                <select
                  v-model="zoomResources"
                  multiple
                  class="border-2 border-gray-300 h-26 rounded-lg w-full text-md mt-2 p-4"
                >
                  <option value="Ficha de inscrição">Ficha de inscrição</option>
                  <option value="Enquete">Enquete</option>
                  <option value="Q&A">Q&A</option>
                </select>
              </label>
            </div>
          </section>

          <!-- OK -->
          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Previsão de acessos

                <input
                  type="text"
                  v-model="accessesPrevision"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>
            </div>
          </section>

          <!-- OK -->
          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Anexar thumbnail

                <div
                  class="w-full border-dashed border border-gray-300 mt-2 py-4 flex items-center justify-center rounded-lg hover:border-blue-500 cursor-pointer"
                >
                  <i class="ph ph-plus"></i>
                </div>

                <input
                  type="file"
                  @change="handleGetFileBufferThumbnail"
                  hidden
                />
              </label>

              <span class="block mt-2 text-sm text-gray-500">{{
                selectedFileThumbnailName
              }}</span>
            </div>
          </section>
        </div>

        <!-- <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Auditórios

              <select class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4">
                <option value="">Selecione uma opção</option>
                <option 
                  v-for="item in auditorys"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
            </label>
          </div>
        </section> -->

        <!-- OK -->
        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Número de participantes

              <input
                type="number"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                v-model="numberOfParticipants"
              />
            </label>
          </div>
        </section>

        <!-- OK -->
        <div>
          <EquipmentSelection
            :equipments="equipments"
            :equipmentsSelect="equipmentsSelect"
            :handleAddEquipment="handleAddEquipment"
            :handleRemoveEquipment="handleRemoveEquipment"
          />
        </div>

        <section class="mt-4">
          <label class="text-sm text-gray-500">
            Observação equipamentos

            <textarea
              v-model="equipmentsDescription"
              class="border-2 border-gray-300 min-h-[120px] resize-y rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </section>

        <!-- OK -->
        <section class="mt-4" v-show="!recurrent">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Exige preparação prévia

              <select
                v-model="previousPreparation"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option value="">Selecione uma opção</option>
                <option :value="true">Sim</option>
                <option :value="false">Não</option>
              </select>
            </label>
          </div>
        </section>

        <!-- OK -->
        <div v-show="previousPreparation">
          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Data de início montagem

                <input
                  v-model="preparationStartDay"
                  type="date"
                  class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>
            </div>
          </section>

          <div class="flex items-center gap-4">
            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário início montagem

                  <input
                    v-model="preparationStartInitial"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>

            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário final montagem

                  <input
                    v-model="preparationStartFinal"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>
          </div>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Data desmontagem

                <input
                  v-model="preparationEndDay"
                  type="date"
                  class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>
            </div>
          </section>

          <div class="flex items-center gap-4">
            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário início desmontagem

                  <input
                    v-model="preparationEndInitial"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>

            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário final desmontagem

                  <input
                    v-model="preparationEndFinal"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>
          </div>
        </div>

        <section class="mt-4" v-show="!previousPreparation">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Programação

              <div class="flex items-end gap-2">
                <input
                  type="text"
                  class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />

                <span class="block mt-2 text-sm text-gray-500">{{
                  selectedFileProgrammingName
                }}</span>

                <label>
                  <div
                    class="w-12 h-12 border-dashed border border-gray-300 rounded-lg flex items-center text-gray-400 justify-center hover:border-blue-500 hover:text-blue-500 transition-all cursor-pointer"
                  >
                    <i class="ph ph-plus"></i>
                  </div>

                  <input
                    type="file"
                    @change="handleGetFileBufferProgramming"
                    hidden
                  />
                </label>
              </div>
            </label>
          </div>
        </section>

        <!-- OK -->
        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Quantidade de palestrantes presenciais

              <input
                type="text"
                v-model="numberOfSpeechers"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-6 flex justify-between items-center">
          <button
            @click="
              handleNextStep(localNameStepRoom === 'Auditórios' ? 3.1 : 3.2)
            "
            type="button"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Voltar</span>
          </button>

          <button
            type="button"
            @click="handleCreateSolicitation"
            :disabled="isLoadingSolicitation"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <div
              v-if="isLoadingSolicitation"
              class="block w-5 h-5 rounded-full border border-gray-400 animate-spin border-t-blue-500"
            ></div>

            <span v-else> Salvar </span>
          </button>
        </section>
      </div>

      <!-- ================== OK ================= -->

      <!-- Step online sala -->
      <div v-show="step === 5.1">
        <h1 class="mt-14 bg-blue-500 text-white px-5 py-2 rounded-lg text-sm">
          • On-line por sala
        </h1>

        <section class="mt-14">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Nome do evento/curso

              <input
                type="text"
                v-model="eventName"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Descrição do evento/curso

              <input
                type="text"
                v-model="eventDescription"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-6 flex justify-between items-center">
          <button
            @click="
              handleNextStep(localNameStepRoom === 'Auditórios' ? 3.1 : 3.2)
            "
            type="button"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Voltar</span>
          </button>

          <button
            type="button"
            :disabled="
              eventName === '' ||
              eventDescription === '' ||
              isLoadingSolicitation
            "
            @click="handleCreateSolicitation"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <div
              v-if="isLoadingSolicitation"
              class="block w-5 h-5 rounded-full border border-gray-400 animate-spin border-t-blue-500"
            ></div>

            <span v-else> Salvar </span>
          </button>
        </section>
      </div>

      <!-- Step presencial sala -->
      <div v-show="step === 5.2">
        <h1 class="mt-14 bg-blue-500 text-white px-5 py-2 rounded-lg text-sm">
          • Presencial por sala
        </h1>

        <section
          v-if="solicitationType === 'Solicitação por data'"
          class="mt-14"
        >
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Layout da sala

              <div class="flex items-end gap-4">
                <select
                  v-model="layoutId"
                  @change="handleGetRooms"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                >
                  <option value="" selected disabled>
                    Selecione um layout de sala
                  </option>
                  <option
                    v-for="item in roomlayoutsdata"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>

                <a
                  v-show="layoutInfoImage.name !== ''"
                  :href="layoutInfoImage.imageUrl"
                  target="_blank"
                >
                  <figure>
                    <img
                      class="w-12 h-12 object-cover rounded-lg ring-2 ring-blue-500"
                      :src="layoutInfoImage.imageUrl"
                      :alt="layoutInfoImage.name"
                    />
                  </figure>
                </a>
              </div>
            </label>
          </div>
        </section>

        <section v-else class="mt-14">
          <div class="flex-1 flex items-end gap-2">
            <label class="text-sm text-gray-500 w-full">
              Layout da sala

              <select
                v-model="layoutId"
                @change="handleGetRooms"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option value="" selected disabled>
                  Selecione um layout de sala
                </option>
                <option
                  v-for="item in layoutsWithRoom"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </label>

            <a
              v-show="layoutInfoImage.name !== ''"
              :href="layoutInfoImage.imageUrl"
              target="_blank"
            >
              <figure>
                <img
                  class="w-12 h-12 object-cover rounded-lg ring-2 ring-blue-500"
                  :src="layoutInfoImage.imageUrl"
                  :alt="layoutInfoImage.name"
                />
              </figure>
            </a>
          </div>
        </section>

        <section class="mt-4" v-show="isActiveInputRoom">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Salas/Auditórios

              <select
                v-model="roomId"
                :disabled="roomsFilterLocal.lenght === 0"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option value="" selected disabled>Selecione uma sala</option>
                <option
                  v-for="room in roomsFilterLocal"
                  :key="room.id"
                  :value="room.id"
                  v-show="room.maintenance === false && room.occult === false"
                >
                  {{ room.name }}
                </option>
              </select>
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Nome do evento/curso

              <input
                type="text"
                v-model="eventName"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Descrição do evento/curso

              <input
                type="text"
                v-model="eventDescription"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Número de participantes

              <input
                type="number"
                v-model="numberOfParticipants"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <div>
          <EquipmentSelection
            :equipments="equipments"
            :equipmentsSelect="equipmentsSelect"
            :handleAddEquipment="handleAddEquipment"
            :handleRemoveEquipment="handleRemoveEquipment"
          />
        </div>

        <section class="mt-4">
          <label class="text-sm text-gray-500">
            Observação equipamentos

            <textarea
              v-model="equipmentsDescription"
              class="border-2 border-gray-300 min-h-[120px] resize-y rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </section>

        <section class="mt-4" v-show="!recurrent">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Exige preparação prévia

              <select
                v-model="previousPreparation"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option value="" selected disabled>Selecione uma opção</option>
                <option :value="true">Sim</option>
                <option :value="false">Não</option>
              </select>
            </label>
          </div>
        </section>

        <div v-show="previousPreparation">
          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Data de início montagem

                <input
                  v-model="preparationStartDay"
                  type="date"
                  class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>
            </div>
          </section>

          <div class="flex items-center gap-4">
            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário início montagem

                  <input
                    v-model="preparationStartInitial"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>

            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário final montagem

                  <input
                    v-model="preparationStartFinal"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>
          </div>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Data desmontagem

                <input
                  v-model="preparationEndDay"
                  type="date"
                  class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>
            </div>
          </section>

          <div class="flex items-center gap-4">
            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário início desmontagem

                  <input
                    v-model="preparationEndInitial"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>

            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário final desmontagem

                  <input
                    v-model="preparationEndFinal"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>
          </div>
        </div>

        <section class="mt-6 flex justify-between items-center">
          <button
            @click="
              handleNextStep2(localNameStepRoom === 'Auditórios' ? 3.1 : 3.2)
            "
            type="button"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Voltar</span>
          </button>

          <button
            type="button"
            :disabled="
              eventName === '' ||
              eventDescription === '' ||
              numberOfParticipants === '' ||
              roomId === null ||
              isLoadingSolicitation
            "
            @click="handleCreateSolicitation"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <div
              v-if="isLoadingSolicitation"
              class="block w-5 h-5 rounded-full border border-gray-400 animate-spin border-t-blue-500"
            ></div>

            <span v-else> Salvar </span>
          </button>
        </section>
      </div>

      <!-- Step hibrido sala -->
      <div v-show="step === 5.3">
        <h1 class="mt-14 bg-blue-500 text-white px-5 py-2 rounded-lg text-sm">
          • Híbrida por sala
        </h1>

        <div
          v-if="solicitationType === 'Solicitação por data'"
          class="flex items-end gap-4"
        >
          <section class="mt-14 w-full">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Layout da sala

                <select
                  v-model="layoutId"
                  @change="handleGetRooms"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                >
                  <option value="" selected disabled>
                    Selecione um layout de sala
                  </option>
                  <option
                    v-for="item in roomlayoutsdata"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </label>
            </div>
          </section>

          <a
            v-show="layoutInfoImage.name !== ''"
            :href="layoutInfoImage.imageUrl"
            target="_blank"
          >
            <figure>
              <img
                class="w-12 h-12 object-cover rounded-lg ring-2 ring-blue-500"
                :src="layoutInfoImage.imageUrl"
                :alt="layoutInfoImage.name"
              />
            </figure>
          </a>
        </div>

        <div v-else class="flex items-end gap-4">
          <section class="mt-14 w-full">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Layout da sala

                <select
                  v-model="layoutId"
                  @change="handleGetRooms"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                >
                  <option value="" selected disabled>
                    Selecione um layout de sala
                  </option>
                  <option
                    v-for="item in layoutsWithRoom"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </label>
            </div>
          </section>

          <a
            v-show="layoutInfoImage.name !== ''"
            :href="layoutInfoImage.imageUrl"
            target="_blank"
          >
            <figure>
              <img
                class="w-12 h-12 object-cover rounded-lg ring-2 ring-blue-500"
                :src="layoutInfoImage.imageUrl"
                :alt="layoutInfoImage.name"
              />
            </figure>
          </a>
        </div>

        <section class="mt-4" v-show="isActiveInputRoom">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Salas/Auditórios

              <select
                v-model="roomId"
                :disabled="roomsFilterLocal.lenght === 0"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option value="" selected disabled>Selecione uma sala</option>
                <option
                  v-for="room in roomsFilterLocal"
                  :key="room.id"
                  :value="room.id"
                  v-show="room.maintenance === false && room.occult === false"
                >
                  {{ room.name }}
                </option>
              </select>
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Nome do evento/curso

              <input
                type="text"
                v-model="eventName"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Descrição do evento/curso

              <input
                type="text"
                v-model="eventDescription"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <section class="mt-4">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Número de participantes

              <input
                type="number"
                v-model="numberOfParticipants"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              />
            </label>
          </div>
        </section>

        <div>
          <EquipmentSelection
            :equipments="equipments"
            :equipmentsSelect="equipmentsSelect"
            :handleAddEquipment="handleAddEquipment"
            :handleRemoveEquipment="handleRemoveEquipment"
          />
        </div>

        <section class="mt-4">
          <label class="text-sm text-gray-500">
            Observação equipamentos

            <textarea
              v-model="equipmentsDescription"
              class="border-2 border-gray-300 min-h-[120px] resize-y rounded-lg w-full text-md mt-2 pl-4"
            />
          </label>
        </section>

        <section class="mt-4" v-show="!recurrent">
          <div class="flex-1">
            <label class="text-sm text-gray-500">
              Exige preparação prévia

              <select
                v-model="previousPreparation"
                class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
              >
                <option value="" selected disabled>Selecione uma opção</option>
                <option :value="true">Sim</option>
                <option :value="false">Não</option>
              </select>
            </label>
          </div>
        </section>

        <div v-show="previousPreparation">
          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Data de início montagem

                <input
                  v-model="preparationStartDay"
                  type="date"
                  class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>
            </div>
          </section>

          <div class="flex items-center gap-4">
            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário início montagem

                  <input
                    v-model="preparationStartInitial"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>

            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário final montagem

                  <input
                    v-model="preparationStartFinal"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>
          </div>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Data desmontagem

                <input
                  v-model="preparationEndDay"
                  type="date"
                  class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>
            </div>
          </section>

          <div class="flex items-center gap-4">
            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário início desmontagem

                  <input
                    v-model="preparationEndInitial"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>

            <section class="mt-4 flex-1">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Horário final desmontagem

                  <input
                    v-model="preparationEndFinal"
                    type="time"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>
          </div>
        </div>

        <section class="mt-6 flex justify-between items-center">
          <button
            @click="
              handleNextStep(localNameStepRoom === 'Auditórios' ? 3.1 : 3.2)
            "
            type="button"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <span>Voltar</span>
          </button>

          <button
            type="button"
            @click="handleCreateSolicitation"
            :disabled="isLoadingSolicitation"
            class="bg-blue-500 py-2 text-white text-sm px-10 rounded-full flex items-center justify-center disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed hover:brightness-90 transition-all"
          >
            <div
              v-if="isLoadingSolicitation"
              class="block w-5 h-5 rounded-full border border-gray-400 animate-spin border-t-blue-500"
            ></div>

            <span v-else> Salvar </span>
          </button>
        </section>
      </div>
    </form>
  </Schema>
</template>

<script>
import Schema from '../../components/Schema/index.vue';
import EquipmentSelection from '../../components/EquipmentSelection/EquipmentSelection.vue';
import RecurrentSolicitation from './components/recurrent-solicitation.vue';
import StepOne from './components/step-one.vue';
import StepTwo from './components/step-two.vue';
import StepThree from './components/step-three.vue';
import api from '../../lib/axios';
import { useToast } from 'vue-toastification';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Calendar from 'primevue/calendar';

import Cookies from 'vue-cookies';

export default {
  name: 'SolicitationView',

  components: {
    Schema,
    EquipmentSelection,
    RecurrentSolicitation,
    Calendar,
    StepOne,
    StepTwo,
    StepThree,
  },

  data() {
    return {
      localsSetWithUnitSelected: [],
      roomsSetWithLocalSelected: [],

      recurrentSolicitationDataForm: {
        defaultRecurrent: 'Diário',
        toggleMonday: true,
        toggleTuesday: true,
        toggleWednesday: true,
        toggleThursday: true,
        toggleFriday: true,
        toggleSaturday: true,
        toggleSunday: true,
        valueMonday: 'segunda-feira',
        valueTuesday: 'terça-feira',
        valueWednesday: 'quarta-feira',
        valueThursday: 'quinta-feira',
        valueFriday: 'sexta-feira',
        valueSaturday: 'sábado',
        valueSunday: 'domingo',
        activeMonday: false,
        activeTuesday: false,
        activeWednesday: false,
        activeThursday: false,
        activeFriday: false,
        activeSaturday: false,
        activeSunday: false,
        endDate: '',
        repeatWeek: '1',
      },

      solicitationType: '',
      localNameStepRoomOurAuditorium: '',
      unitName: '',
      roomsAppointments: [],
      layoutsWithRoom: [],
      isActiveInputRoom: true,
      localsError: [],
      isActiveLocalError: false,
      layoutsWithLocal: [],
      layoutId: '',
      layoutName: '',
      auditorys: [],
      equipmentsSelect: [],
      equipmentsSelectId: [],
      isBlockedButtonNext: true,
      name: '',
      phone: '',
      email: '',
      active: true,
      deleted: false,
      unitId: null,
      locals: [],
      localId: '',
      localNameStepRoom: '',
      date: '',
      startTime: '',
      finalTime: '',
      recurrent: false,
      recurrentPattern: '',
      costCenter: '',
      manager: '',
      board: '',
      roomId: null,
      roomType: '',
      roomTypeAuditorium: '',
      eventName: '',
      eventDescription: '',
      numberOfParticipants: 0,
      previousPreparation: false,
      preparationStartDay: '',
      preparationStartInitial: '',
      preparationStartFinal: '',
      preparationEndDay: '',
      preparationEndInitial: '',
      preparationEndFinal: '',
      equipments: [],
      status: '',
      numberOfSpeechers: 0,
      transmission: false,
      typeOfTransmission: '',
      transmissionDescription: '',
      transmissionAudioFile: '',
      socialNetworkText: '',
      typeOfLink: '',
      thumbNail: '',
      programmingText: '',
      programmingFile: '',
      zoomResources: [],
      accessesPrevision: 0,
      branch: '',
      requesterEmail: '',
      roomLayout: '',
      mobileEquipments: [],
      messages: '',
      equipmentsDescription: '',
      roomsFilterLocal: [],

      isLoadingSolicitation: false,

      roomsdata: [],

      appointmentsRegister: [],

      isBlockedButtonNextLocal: false,

      selectedFileAudio: null,
      selectedFileAudioName: '',

      selectedFileThumbnail: null,
      selectedFileThumbnailName: '',

      selectedFileProgramming: null,
      selectedFileProgrammingName: '',

      layoutInfoImage: {
        name: '',
        imageUrl: '',
      },

      roomTypeByRoomSelect: '',
    };
  },

  setup() {
    const toast = useToast();
    return { toast };
  },

  created() {
    this.$store.dispatch('SolicitationStore/setUserData');
    this.$store.dispatch('SolicitationStore/cleanData');
  },

  computed: {
    step: {
      get() {
        return this.$store.state.SolicitationStore.step;
      },
    },

    user() {
      return this.$store.state.Auth.user;
    },

    unitsdata() {
      return this.$store.state.Units.unitsdata;
    },

    locationsdata() {
      return this.$store.state.Locations.locationsdata;
    },

    roomlayoutsdata() {
      return this.$store.state.RoomLayouts.roomlayoutsdata;
    },

    equipmentsdata() {
      return this.$store.state.Equipments.equipmentsdata;
    },

    unitsData() {
      return this.$store.state.Units.unitsdata;
    },

    profile() {
      return this.$store.state.Default.profile;
    },
  },

  mounted() {
    // Api call locals
    this.$store.dispatch('Locations/getLocationsByData', '/locals');

    this.$store.dispatch('Rooms/getRoomsByData', '/rooms');

    this.$store.dispatch('RoomLayouts/getRoomLayoutByData', '/roomLayouts');

    this.$store.dispatch('Equipments/getEquipmentsByData', '/equipments');

    this.$store.dispatch('Units/getUnitsByData', '/units');

    this.getAllRooms();
  },

  methods: {
    getNameDayOfWeekByDate() {
      const nameDayOfWeek = format(this.date, 'EEEE', {
        locale: ptBR,
      });

      this.recurrentSolicitationDataForm.activeMonday = false;
      this.recurrentSolicitationDataForm.activeTuesday = false;
      this.recurrentSolicitationDataForm.activeWednesday = false;
      this.recurrentSolicitationDataForm.activeThursday = false;
      this.recurrentSolicitationDataForm.activeFriday = false;
      this.recurrentSolicitationDataForm.activeSaturday = false;
      this.recurrentSolicitationDataForm.activeSunday = false;

      if (this.recurrentSolicitationDataForm.valueMonday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeMonday = true;
      }

      if (this.recurrentSolicitationDataForm.valueTuesday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeTuesday = true;
      }

      if (this.recurrentSolicitationDataForm.valueWednesday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeWednesday = true;
      }

      if (this.recurrentSolicitationDataForm.valueThursday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeThursday = true;
      }

      if (this.recurrentSolicitationDataForm.valueFriday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeFriday = true;
      }

      if (this.recurrentSolicitationDataForm.valueSaturday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeSaturday = true;
      }

      if (this.recurrentSolicitationDataForm.valueSunday === nameDayOfWeek) {
        this.recurrentSolicitationDataForm.activeSunday = true;
      }
    },

    handleGetLocalsWithUnit() {
      api
        .get(`units/${Number(this.unitId)}`, {
          headers: {
            token: this.$cookies.get('token'),
          },
        })
        .then((response) => {
          // Locais
          const filterLocalsWithUnitId = this.locationsdata.filter(
            (local) => local.unitId === response.data.id
          );

          this.localsSetWithUnitSelected = filterLocalsWithUnitId;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleGetRoomsWithLocalId() {
      const filterRoomsWithLocalId = this.roomsdata.filter(
        (room) =>
          room.localId === Number(this.localId) &&
          room.active === true &&
          !room.maintenance &&
          !room.occult
      );
      this.roomsSetWithLocalSelected = filterRoomsWithLocalId;
      this.roomsSetWithLocalSelected.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
      );
    },

    // STEP por data
    getLocalWithUnit() {
      const unitId = Number(this.unitId);
      const token = Cookies.get('token');

      api
        .get(`/units/${unitId}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.locals = res.data.Locals;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getAllRooms() {
      const token = Cookies.get('token');

      api
        .get('rooms', {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          const filter = res.data.filter(
            (room) => room.maintenance === false && room.occult === false
          );
          this.roomsdata = filter;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Pega os dados relacionados ao local
    getDataForLocalSelected() {
      const equipmentsFilter = this.equipmentsdata.filter(
        (equip) => equip.getLocalId.id === this.localId
      );
      const filter = equipmentsFilter.filter((equip) => equip.type === 1);
      this.equipments = filter;
      const token = Cookies.get('token');

      api
        .get(`/locals/${this.localId}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
            
          if (res.data.name === 'Auditórios') {
            this.localNameStepRoom = 'Auditórios';
          } else {
            this.localNameStepRoom = 'Sala';
          }

          this.auditorys = res.data.Rooms.filter(
            (room) => !room.maintenance && !room.occult
          );
        })
        .catch((err) => {
          console.log(err);
        });

      api
        .get(`locals/${this.localId}/layouts`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.layoutsWithLocal = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleNextStep(step) {
      this.step = step;
    },

    handleNextStep2(step) {
      this.step = step;

      if (this.solicitationType === 'Solicitação por data') {
        this.isActiveInputRoom = true;
      } else {
        this.isActiveInputRoom = false;
      }
    },

    // Função para pegar o local e a unidade
    handleGetLocalAndUnit() {
      const roomId = this.roomId;
      const token = Cookies.get('token');

      api
        .get(`/rooms/${roomId}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.layoutsWithRoom = res.data.getLayouts;
          this.isActiveInputRoom = false;
          this.localId = res.data.getLocalId.id;

          // Get Local
          api
            .get(`/locals/${res.data.getLocalId.id}`, {
              headers: {
                token: token,
              },
            })
            .then((res) => {
            
              if (res.data.name === 'Auditórios') {
                this.localNameStepRoom = 'Auditórios';
              } else {
                this.localNameStepRoom = 'Sala';
              }

              const filterEquipmentsByLocal = this.equipmentsdata.filter(
                (equip) => equip.localId === res.data.id
              );
              const filterTypeMovel = filterEquipmentsByLocal.filter(
                (equip) => equip.type === 1
              );

              this.equipments = filterTypeMovel;
            })
            .catch((err) => {
              console.log(err);
            });

          api
            .get(`/units/${res.data.getLocalId.unitId}`, {
              headers: {
                token: token,
              },
            })
            .then((res) => {
              this.unitName = res.data.name;
              this.unitId = Number(res.data.id);
            })
            .catch((err) => {
              console.log(err);
            });

          api
            .get(`/roomtypes/${res.data.roomTypeId}`, {
              headers: {
                token: token,
              },
            })
            .then((res) => {
              this.roomTypeByRoomSelect = res.data.name;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleGetDisponibilityData() {
      this.toast.success('Horário de funcionamento permitido');
      this.isBlockedButtonNext = false;
      this.isBlockedButtonNextLocal = false;
    },

    handleGetDisponibilityAudit() {
      const data = {
        roomId: this.roomId,
        date: format(this.date, 'yyyy-MM-dd'),
        startTime: this.startTime,
        finalTime: this.finalTime,
        localId: this.localId,
      };

      api
        .post('/appointments/room/date', data, {
          headers: {
            token: this.$cookies.get('token'),
          },
        })
        .then((response) => {
          if (response.data.blocked) {
            this.isBlockedButtonNext = true;
            this.isBlockedButtonNextLocal = true;
            this.toast.warning(response.data.message);
          } else {
            this.isBlockedButtonNext = false;
            this.isBlockedButtonNextLocal = false;
            this.toast.success(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Função para pegar todos os layouts por id
    handleGetRooms() {
      api
        .get(`/roomlayouts/${this.layoutId}`, {
          headers: {
            token: this.$cookies.get('token'),
          },
        })
        .then((res) => {
          this.layoutInfoImage = {
            name: res.data.name,
            imageUrl: res.data.imageUrl,
          };

          this.roomLayout = res.data.name;

          // Array 1
          const rooms = res.data.Rooms.filter(
            (item) => item.localId === this.localId
          );

          if (this.appointmentsRegister.length > 0) {
            let appointmentsArrayId = [];

            for (let i = 0; i < this.appointmentsRegister.length; i++) {
              appointmentsArrayId.push(this.appointmentsRegister[i].room);
            }

            const resultArray = rooms.filter(
              (item) => !appointmentsArrayId.includes(item.id)
            );
            this.roomsFilterLocal = resultArray;
          } else {
            this.roomsFilterLocal = rooms;
          }

          this.roomsFilterLocal = this.roomsFilterLocal.filter(
            (room) => !room.maintenance && !room.occult
          );

          this.roomsFilterLocal.sort((a, b) =>
            a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Função para adicionar um equipamento
    handleAddEquipment(id, name) {
      this.equipmentsSelect = [
        ...this.equipmentsSelect,
        {
          id: id,
          name: name,
        },
      ];

      this.equipmentsSelectId = [
        ...this.equipmentsSelectId,
        {
          id: id,
        },
      ];

      const filterEquipments = this.equipments.filter((item) => item.id !== id);
      this.equipments = filterEquipments;
    },

    // Função para remover um equipamento
    handleRemoveEquipment(id, name) {
      const updateEquipment = this.equipmentsSelect.filter(
        (item) => item.id != id
      );
      this.equipmentsSelect = updateEquipment;

      const updateEquipmentId = this.equipmentsSelectId.filter(
        (item) => item.id != id
      );
      this.equipmentsSelectId = updateEquipmentId;

      this.equipments = [
        ...this.equipments,
        {
          id: id,
          name: name,
        },
      ];
    },

    // Função de criar uma solicitação
    async handleCreateSolicitation() {
      let finalDate = this.date;

      if (this.recurrent) {
        const data = {
          name: this.eventName,
          currentDate: format(this.date, 'yyyy-MM-dd'),
          lastDate: format(
            this.recurrentSolicitationDataForm.endDate,
            'yyyy-MM-dd'
          ),
          days: [
            {
              name: 'Segunda-feira',
              active: this.recurrentSolicitationDataForm.toggleMonday,
            },
            {
              name: 'Terça-feira',
              active: this.recurrentSolicitationDataForm.toggleTuesday,
            },
            {
              name: 'Quarta-feira',
              active: this.recurrentSolicitationDataForm.toggleWednesday,
            },
            {
              name: 'Quinta-feira',
              active: this.recurrentSolicitationDataForm.toggleThursday,
            },
            {
              name: 'Sexta-feira',
              active: this.recurrentSolicitationDataForm.toggleFriday,
            },
            {
              name: 'Sábado',
              active: this.recurrentSolicitationDataForm.toggleSaturday,
            },
            {
              name: 'Domingo',
              active: this.recurrentSolicitationDataForm.toggleSunday,
            },
          ],
          defaultRecurrent: this.recurrentSolicitationDataForm.defaultRecurrent,
          repeatWeek: Number(this.recurrentSolicitationDataForm.repeatWeek),
        };

        await api
          .post('/recurrent', data, {
            headers: {
              token: this.$cookies.get('token'),
            },
          })
          .then((res) => {
            this.recurrentPattern = JSON.stringify(res.data);
            const decoded = JSON.parse(this.recurrentPattern);

            finalDate = new Date(
              decoded.recurrent[decoded.recurrent.length - 1].currentDate +
                ' ' +
                '00:00:00'
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }

      let roomFormatted = null;

      if (this.roomId === null) {
        roomFormatted = this.roomId;
      } else {
        roomFormatted = Number(this.roomId);
      }

      let roomTypeFormatted = null;

      if (this.roomTypeAuditorium === '') {
        roomTypeFormatted = this.roomType;
      } else {
        roomTypeFormatted = this.roomTypeAuditorium;
      }

      const userId = Cookies.get('user_id');

      const data = {
        active: true,
        deleted: false,
        name: this.user.name,
        createdBy: Number(userId),
        status: 'Pendente',
        eventName: this.eventName,
        eventDescription: this.eventDescription,
        numberOfParticipants: Number(this.numberOfParticipants),
        numberOfSpeechers: Number(this.numberOfSpeechers),
        transmission: this.transmission,
        typeOfTransmission: this.typeOfTransmission,
        transmissionDescription: this.transmissionDescription,
        transmissionAudioFile: this.transmissionAudioFile,
        socialNetworkText: this.socialNetworkText,
        thumbNail: this.thumbNail,
        programmingText: this.programmingText,
        programmingFile: this.programmingFile,
        typeOfLink: this.typeOfLink,
        zoomResources: String(this.zoomResources),
        accessesPrevision: Number(this.accessesPrevision),
        branch: this.branch,
        email: this.user.email,
        requesterEmail: this.user.email,
        previousPreparation: this.previousPreparation,
        preparationStartDay: this.preparationStartDay,
        preparationStartInitial: this.preparationStartInitial,
        preparationStartFinal: this.preparationStartFinal,
        preparationEndDay: this.preparationEndDay,
        preparationEndInitial: this.preparationEndInitial,
        preparationEndFinal: this.preparationEndFinal,
        roomId: roomFormatted,
        roomType: roomTypeFormatted,
        roomLayout: this.roomLayout,
        date: format(this.date, 'yyyy-MM-dd'),
        startTime: this.startTime,
        finalTime: this.finalTime,
        costCenter: this.costCenter,
        recurrent: this.recurrent,
        recurrentPattern: this.recurrentPattern,
        board: this.board,
        manager: this.manager,
        localId: Number(this.localId),
        unitId: Number(this.unitId),
        equipmentsDescription: this.equipmentsDescription,
        messages: this.messages,
        finalDate: finalDate,
        mobileEquipments: {
          connect: this.equipmentsSelectId,
        },
      };

      const token = this.$cookies.get('token');

      this.isLoadingSolicitation = true;

      api
        .post('/solicitation', data, {
          headers: {
            token: token,
          },
        })
        .then((resSolicitation) => {
          if (this.roomId !== null) {
            api
              .get(`/rooms/${this.roomId}`, {
                headers: {
                  token: token,
                },
              })
              .then((res) => {
                if (res.data.selfReserve === true) {
                  this.$store.commit('Default/CHANGE_MODAL_RELOAD', true);
                  const updatedBy = Cookies.get('user_id');

                  const data = {
                    solicitation: resSolicitation.data.id,
                    status: 'Aprovada',
                    updatedBy: Number(updatedBy),
                  };
                  const token = Cookies.get('token');

                  api
                    .post('/solicitation/status', data, {
                      headers: {
                        token: token,
                      },
                    })
                    .then(() => {})
                    .catch((err) => {
                      console.log(err);
                    });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }

          this.toast.success('Solicitação criada com sucesso');
          this.$router.push('/gerenciamento/reservas');
          this.isLoadingSolicitation = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingButton = false;
          this.isLoadingSolicitation = false;
        });
    },

    handleGetFileBufferAudioFile(event) {
      this.selectedFile = event.target.files[0];
      this.selectedFileAudioName = event.target.files[0].name;

      const formData = new FormData();
      formData.append('file', this.selectedFile);
      const token = this.$cookies.get('token');

      api
        .post('/solicitation/upload', formData, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.transmissionAudioFile = res.data.url;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleGetFileBufferThumbnail(event) {
      this.selectedFileThumbnail = event.target.files[0];
      this.selectedFileThumbnailName = event.target.files[0].name;

      const formData = new FormData();
      formData.append('file', this.selectedFileThumbnail);
      const token = this.$cookies.get('token');

      api
        .post('/solicitation/upload', formData, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.thumbNail = res.data.url;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleGetFileBufferProgramming(event) {
      this.selectedFileProgramming = event.target.files[0];
      this.selectedFileProgrammingName = event.target.files[0].name;

      const formData = new FormData();
      formData.append('file', this.selectedFileProgramming);
      const token = this.$cookies.get('token');

      api
        .post('/solicitation/upload', formData, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          this.programmingFile = res.data.url;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
