<template>
  <Schema>
    <div class="w-full">
      <!-- Breadcrumb -->
      <div class="flex w-full mb-4 text-sm">
        <div class="mb-4 text-gray-400">
          <router-link to="/app" class="hover:text-blue-500 transition-colors">
            Home
          </router-link>
          <button
            class="hover:text-blue-500 transition-all"
            type="button"
            @click="goToSolicitations()"
          >
            • Reservas
          </button>
          <span class="text-blue-500">
            • Editar solicitação #{{ solicitationId }}</span
          >
        </div>
      </div>

      <!-- Botoes iniciais de status, mensagens e histórico -->
      <div class="flex items-center justify-between">
        <div class="flex flex-col">
          <h1 class="text-3xl text-gray-600">Editar solicitação</h1>
          <p class="text-gray-400 max-w-xs mt-1">
            Modifique a solicitação realizada
          </p>
        </div>

        <div class="flex items-end gap-4">
          <div class="h-12 flex items-center">
            <span
              v-show="isLoadingApprovedReserve"
              class="w-6 h-6 border-2 border-t-blue-500 rounded-full block animate-spin"
            ></span>
          </div>

          <div class="flex flex-col">
            <label class="text-sm text-gray-500">Status</label>

            <select
              v-model="solicitationData.status"
              @change="handleOpenDialog"
              class="border-2 border-gray-300 h-12 rounded-lg w-[250px] text-md mt-2 pl-4 text-sm"
              :disabled="this.userInfo.role === 'Básico'"
            >
              <option value="" selected disabled>Selecione um status</option>
              <option value="Pendente">Pendente</option>
              <option
                value="Aprovada"
                :disabled="
                  !this.solicitationData.roomId &&
                  this.solicitationData.roomType !== 'On-line'
                "
              >
                Aprovada
              </option>
              <option value="Cancelada">Cancelada</option>
              <option
                value="Reprovada"
                :disabled="
                  !this.solicitationData.roomId &&
                  this.solicitationData.roomType !== 'On-line'
                "
              >
                Reprovada
              </option>
              <option value="Parcialmente aprovada" disabled>
                Parcialmente aprovada
              </option>
            </select>
          </div>

          <div class="relative">
            <button
              type="button"
              @click="isActiveComunicationChat = !isActiveComunicationChat"
              class="bg-blue-500 text-white h-12 rounded-lg w-12 text-xl mt-2 flex items-center justify-center gap-2 hover:brightness-90 transition-all"
            >
              <i
                :class="
                  isActiveComunicationChat
                    ? 'ph ph-x'
                    : 'ph ph-chat-teardrop-text'
                "
              ></i>
            </button>

            <div
              :class="`absolute right-0 z-40 top-20 bg-gray-600 text-white rounded-lg w-[400px] shadow-xl p-4 transition-all duration-500 ${
                isActiveComunicationChat
                  ? 'opacity-100 visible'
                  : 'opacity-0 invisible'
              } `"
            >
              <div class="flex items-center justify-between">
                <h1 class="text-sm">Canal de comunicação</h1>
                <span
                  class="bg-gray-500 text-white px-2 py-1 rounded-lg text-sm"
                  >{{ messages.length }}
                  {{ messages.length > 1 ? 'mensagens' : 'mensagem' }}</span
                >
              </div>

              <div
                class="h-[350px] overflow-y-auto bg-gray-500 mt-4 flex flex-col gap-2 rounded-t-lg p-4"
              >
                <div
                  v-for="message in messages"
                  :key="message.id"
                  :class="`flex ${
                    userIdValue === message.getUserId.id
                      ? 'justify-end'
                      : 'justify-start'
                  }`"
                >
                  <div
                    :class="`bg-gray-600 px-3 py-2 w-1/2 text-sm  ${
                      userIdValue === message.getUserId.id
                        ? 'rounded-b-lg rounded-tl-lg'
                        : 'rounded-b-lg rounded-tr-lg'
                    }`"
                  >
                    {{ message.message }}
                  </div>
                </div>
              </div>

              <div class="flex items-center">
                <input
                  type="text"
                  class="flex-1 h-10 text-gray-400 rounded-bl-lg px-4 text-sm outline-none focus:ring-2 focus:ring-cyan-500 transition-all"
                  placeholder="Digite sua mensagem aqui..."
                  v-model="message"
                />
                <button
                  type="button"
                  :disabled="isLoadingSendingMessage || message === ''"
                  class="h-10 w-10 bg-cyan-500 text-g disabled:opacity-60 disabled:cursor-not-allowed disabled:hover:brightness-100 rounded-br-lg flex items-center justify-center hover:brightness-90 transition-all"
                  @click="handleCreateNewMessage"
                >
                  <i
                    class="ph ph-paper-plane-right"
                    v-if="!isLoadingSendingMessage"
                  ></i>
                  <span
                    v-else
                    class="w-4 h-4 border border-t-blue-500 rounded-full block animate-spin"
                  ></span>
                </button>
              </div>
            </div>
          </div>

          <div
            class="relative group cursor-help bg-blue-500 w-12 h-12 text-white flex items-center justify-center rounded-lg text-xl"
          >
            <i class="ph ph-clock-counter-clockwise"></i>

            <div
              class="absolute shadow-lg right-0 top-14 bg-gray-500 text-sm min-w-[430px] rounded-lg p-4 flex flex-col gap-2 z-50 transition-all scale-0 origin-top-right group-hover:scale-100"
            >
              <div
                class="flex items-center justify-between border-b border-gray-400 p-3"
                v-for="hist in history"
                :key="hist.id"
              >
                <div class="w-full">
                  <div class="flex items-center justify-between w-full">
                    <h1 class="text-lg">{{ hist.status }}</h1>
                    <span class="text-xs">{{ hist.createdAt }}</span>
                  </div>
                  <span class="opacity-70"
                    >Realizada por {{ hist.username }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-start gap-4 mt-14">
        <div class="flex-1 flex flex-col gap-4">
          <div>
            <label class="text-sm text-gray-500">Nome</label>
            <input
              type="text"
              v-model="solicitationData.name"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
              disabled
            />
          </div>

          <div>
            <label class="text-sm text-gray-500">Ramal/Telefone</label>
            <input
              type="text"
              v-model="solicitationData.branch"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
              disabled
            />
          </div>

          <div>
            <label class="text-sm text-gray-500">Local</label>

            <select
              v-model="solicitationData.localId"
              disabled
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
            >
              <option v-for="local in locals" :key="local.id" :value="local.id">
                {{ local.name }}
              </option>
            </select>
          </div>

          <!-- StartTime -->
          <div>
            <label class="text-sm text-gray-500">Horário inicial</label>
            <input
              type="time"
              :disabled="solicitationData.recurrent"
              v-model="solicitationData.startTime"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
            />
          </div>

          <!-- CostCenter -->
          <div>
            <label class="text-sm text-gray-500">Centro de custo</label>
            <input
              type="text"
              :disabled="solicitationData.status === 'Aprovada'"
              v-model="solicitationData.costCenter"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
            />
          </div>

          <!-- Board -->
          <div>
            <label class="text-sm text-gray-500">Diretoria</label>
            <input
              type="text"
              :disabled="solicitationData.status === 'Aprovada'"
              v-model="solicitationData.board"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
            />
          </div>
        </div>

        <!-- Parte 02 -->
        <div class="flex-1 flex flex-col gap-4">
          <!-- Email -->
          <div>
            <label class="text-sm text-gray-500">E-mail</label>
            <input
              type="text"
              v-model="solicitationData.email"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
              disabled
            />
          </div>

          <!-- Unit -->
          <div>
            <label class="text-sm text-gray-500">Unidade</label>

            <select
              v-model="solicitationData.unitId"
              disabled
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
            >
              <option v-for="unit in units" :key="unit.id" :value="unit.id">
                {{ unit.name }}
              </option>
            </select>
          </div>

          <!-- Date -->
          <div>
            <label class="text-sm text-gray-500">Data</label>
            <input
              type="date"
              :disabled="
                solicitationData.status === 'Aprovada' ||
                Boolean(solicitationData.recurrent)
              "
              v-model="solicitationData.date"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
            />
          </div>

          <!-- FinalTime -->
          <div>
            <label class="text-sm text-gray-500">Horário final</label>
            <input
              type="time"
              :disabled="solicitationData.recurrent"
              v-model="solicitationData.finalTime"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
            />
          </div>

          <!-- Manager -->
          <div>
            <label class="text-sm text-gray-500">Gerência</label>
            <input
              type="text"
              :disabled="solicitationData.status === 'Aprovada'"
              v-model="solicitationData.manager"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
            />
          </div>

          <!-- RoomType -->
          <div>
            <label class="text-sm text-gray-500">Tipo de sala</label>

            <select
              :disabled="true"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
              v-model="solicitationData.roomType"
            >
              <option value="On-line">On-line</option>
              <option value="Presencial">Presencial</option>
              <option value="Híbrido">Híbrido</option>
            </select>
          </div>
        </div>
      </div>

      <div class="flex items-start gap-4 mt-10 pt-10 border-t border-gray-200">
        <div class="flex-1 flex flex-col gap-4">
          <!-- EventName -->
          <div>
            <label class="text-sm text-gray-500">Nome do evento/curso</label>
            <input
              type="text"
              :disabled="solicitationData.status === 'Aprovada'"
              v-model="solicitationData.eventName"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
            />
          </div>

          <div v-if="solicitationData.roomType !== 'On-line'">
            <label class="text-sm text-gray-500">Sala</label>

            <select
              v-model="solicitationData.roomId"
              :disabled="solicitationData.status === 'Aprovada'"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
              @change="handleRoomChange($event)"
            >
              <option :value="null" selected disabled>
                Sem sala selecionada
              </option>
              <option v-for="room in rooms" :key="room.id" :value="room.id">
                {{ room.name }}
              </option>
            </select>
          </div>

          <!-- Number of participants -->
          <div>
            <label class="text-sm text-gray-500">Número de participantes</label>
            <input
              type="text"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
              v-model="solicitationData.numberOfParticipants"
              :disabled="
                solicitationData.numberOfParticipants === 0 ||
                solicitationData.status === 'Aprovada'
              "
            />
          </div>

          <div>
            <section class="mt-4">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Data de início montagem

                  <input
                    type="date"
                    v-model="solicitationData.preparationStartDay"
                    :disabled="solicitationData.status === 'Aprovada'"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>

            <div class="flex items-center gap-4">
              <section class="mt-4 flex-1">
                <div class="flex-1">
                  <label class="text-sm text-gray-500">
                    Horário início montagem

                    <input
                      type="time"
                      v-model="solicitationData.preparationStartInitial"
                      :disabled="solicitationData.status === 'Aprovada'"
                      class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                    />
                  </label>
                </div>
              </section>

              <section class="mt-4 flex-1">
                <div class="flex-1">
                  <label class="text-sm text-gray-500">
                    Horário final montagem

                    <input
                      type="time"
                      v-model="solicitationData.preparationStartFinal"
                      :disabled="solicitationData.status === 'Aprovada'"
                      class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                    />
                  </label>
                </div>
              </section>
            </div>

            <section class="mt-4">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Data desmontagem

                  <input
                    :disabled="solicitationData.status === 'Aprovada'"
                    type="date"
                    v-model="solicitationData.preparationEndDay"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>

            <div class="flex items-center gap-4">
              <section class="mt-4 flex-1">
                <div class="flex-1">
                  <label class="text-sm text-gray-500">
                    Horário início desmontagem

                    <input
                      :disabled="solicitationData.status === 'Aprovada'"
                      type="time"
                      v-model="solicitationData.preparationEndInitial"
                      class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                    />
                  </label>
                </div>
              </section>

              <section class="mt-4 flex-1">
                <div class="flex-1">
                  <label class="text-sm text-gray-500">
                    Horário final desmontagem

                    <input
                      :disabled="solicitationData.status === 'Aprovada'"
                      type="time"
                      v-model="solicitationData.preparationEndFinal"
                      class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                    />
                  </label>
                </div>
              </section>
            </div>
          </div>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Texto para rede social

                <input
                  v-model="solicitationData.socialNetworkText"
                  type="text"
                  :disabled="
                    solicitationData.socialNetworkText === '' ||
                    solicitationData.status === 'Aprovada'
                  "
                  :placeholder="
                    solicitationData.socialNetworkText === '' &&
                    'Sem texto para rede social'
                  "
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>
            </div>
          </section>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                <div class="flex items-center justify-between">
                  Tipo de link
                </div>

                <select
                  :disabled="
                    solicitationData.typeOfLink === '' ||
                    solicitationData.status === 'Aprovada'
                  "
                  v-model="solicitationData.typeOfLink"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                >
                  <option
                    value=""
                    v-show="solicitationData.typeOfLink === ''"
                    selected
                    disabled
                  >
                    Sem tipo de link
                  </option>
                  <option value="Webinar">
                    Webinar (até 1.000 ou 3.000 participantes, apenas
                    palestrante tem acesso à câmera)
                  </option>
                  <option value="Reunião">
                    Reunião (até 300 participantes, todos acessam possuem acesso
                    à câmera)
                  </option>
                </select>
              </label>
            </div>
          </section>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Recursos do zoom

                <select
                  :disabled="solicitationData.status === 'Aprovada'"
                  v-model="solicitationData.zoomResources"
                  multiple
                  class="border-2 border-gray-300 h-26 rounded-lg w-full text-md mt-2 p-4"
                >
                  <option value="Ficha de inscrição">Ficha de inscrição</option>
                  <option value="Enquete">Enquete</option>
                  <option value="Q&A">Q&A</option>
                </select>
              </label>
            </div>
          </section>

          <div class="bg-gray-100 p-3 rounded-lg">
            <label class="text-sm text-gray-500"
              >Recursos do Zoom selecionados</label
            >

            <div class="mt-2 flex flex-col gap-2">
              <div
                v-for="item in solicitationData.zoomResources"
                :key="item"
                class="bg-blue-500 text-white p-2 rounded-lg"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>

        <div class="flex-1 flex flex-col gap-4">
          <div>
            <label class="text-sm text-gray-500"
              >Descrição do evento/curso</label
            >
            <input
              type="text"
              :disabled="solicitationData.status === 'Aprovada'"
              v-model="solicitationData.eventDescription"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
            />
          </div>

          <div v-if="solicitationData.roomType !== 'On-line'">
            <label class="text-sm text-gray-500">Layout da sala</label>

            <select
              v-model="solicitationData.roomLayout"
              class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
              @change="handleLayoutChange($event)"
              :disabled="
                solicitationData.roomLayout === '' ||
                solicitationData.status === 'Aprovada'
              "
            >
              <option
                v-show="solicitationData.roomLayout === ''"
                value=""
                selected
                disabled
              >
                Layout não escolhido
              </option>
              <option
                v-for="roomLayout in roomLayouts"
                :key="roomLayout.id"
                :value="roomLayout.name"
              >
                {{ roomLayout.name }}
              </option>
            </select>
          </div>

          <section class="mt-4 flex items-start gap-8">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Equipamentos selecionados

                <div
                  class="bg-gray-100 p-4 rounded-lg flex flex-col gap-2 h-[200px] overflow-y-auto mt-2 cursor-not-allowed"
                >
                  <div
                    v-for="equipment in solicitationData.mobileEquipments"
                    :key="equipment.id"
                    class="bg-blue-500 flex items-center justify-between text-white px-4 py-2 rounded-lg"
                  >
                    <span>{{ equipment.name }}</span>

                    <button
                      type="button"
                      :disabled="solicitationData.status === 'Aprovada'"
                      @click="disconnectEquipment(equipment.id)"
                      class="bg-red-500 w-6 h-6 flex items-center justify-center rounded-full cursor-pointer hover:brightness-90 transition-all"
                    >
                      <i class="ph ph-trash"></i>
                    </button>
                  </div>
                </div>
              </label>
            </div>

            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Equipamentos

                <div
                  class="bg-gray-100 p-4 rounded-lg flex flex-col gap-2 h-[200px] overflow-y-auto mt-2"
                >
                  <div
                    v-for="equipment in equipments"
                    :key="equipment.id"
                    v-show="equipment.type === 1"
                    class="bg-blue-500 flex items-center justify-between text-white px-4 py-2 rounded-lg"
                  >
                    <span>{{ equipment.name }}</span>

                    <button
                      type="button"
                      :disabled="solicitationData.status === 'Aprovada'"
                      @click="connectEquipment(equipment.id)"
                      class="bg-green-500 w-6 h-6 flex items-center justify-center rounded-full cursor-pointer hover:brightness-90 transition-all"
                    >
                      <i class="ph ph-plus"></i>
                    </button>
                  </div>
                </div>
              </label>
            </div>
          </section>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Descrição para os equipamentos

                <textarea
                  type="text"
                  :disabled="
                    solicitationData.equipmentsDescription === '' ||
                    solicitationData.status === 'Aprovada'
                  "
                  class="border-2 border-gray-300 min-h-[120px] resize-y rounded-lg w-full text-md mt-2 p-4 disabled:opacity-60"
                  v-model="solicitationData.equipmentsDescription"
                />
              </label>
            </div>
          </section>

          <div class="bg-gray-100 rounded-lg p-4">
            <section class="mt-4">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  <div class="flex items-center justify-between">
                    Tipo de transmissão
                  </div>

                  <select
                    :disabled="
                      solicitationData.typeOfTransmission === '' ||
                      solicitationData.status === 'Aprovada'
                    "
                    v-model="solicitationData.typeOfTransmission"
                    class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4 text-sm"
                  >
                    <option
                      value=""
                      v-show="solicitationData.typeOfTransmission === ''"
                      selected
                      disabled
                    >
                      Sem tipo de transmissão
                    </option>
                    <option value="Zoom">Zoom</option>
                    <option value="Workplace">Workplace</option>
                    <option value="Facebook">Facebook</option>
                    <option value="Youtube">Youtube</option>
                  </select>
                </label>
              </div>
            </section>

            <section class="mt-4">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Workplace, Facebook e Youtube

                  <input
                    :disabled="
                      solicitationData.typeOfTransmission === '' ||
                      solicitationData.status === 'Aprovada'
                    "
                    type="text"
                    class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />
                </label>
              </div>
            </section>

            <section class="mt-4">
              <div class="flex-1">
                <label class="text-sm text-gray-500">
                  Trilha sonora para o início da live

                  <div
                    class="w-full border-dashed border border-gray-300 mt-2 py-4 flex items-center justify-center rounded-lg cursor-pointer opacity-60"
                  >
                    <i class="ph ph-file-audio"></i>
                  </div>

                  <input type="file" disabled hidden />
                </label>
              </div>
            </section>
          </div>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Previsão de acessos

                <input
                  type="text"
                  :disabled="
                    solicitationData.accessesPrevision === 0 ||
                    solicitationData.status === 'Aprovada'
                  "
                  v-model="solicitationData.accessesPrevision"
                  class="border-2 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                />
              </label>
            </div>
          </section>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Anexar thumbnail

                <div
                  class="w-full border-dashed border border-gray-300 mt-2 py-4 flex items-center justify-center rounded-lg opacity-60 cursor-pointer"
                >
                  <i class="ph ph-plus"></i>
                </div>

                <input type="file" disabled hidden />
              </label>
            </div>
          </section>

          <section class="mt-4">
            <div class="flex-1">
              <label class="text-sm text-gray-500">
                Programação

                <div class="flex items-end gap-2">
                  <input
                    type="text"
                    :disabled="solicitationData.programmingText === ''"
                    v-model="solicitationData.programmingText"
                    class="border-2 flex-1 border-gray-300 h-12 rounded-lg w-full text-md mt-2 pl-4"
                  />

                  <label>
                    <div
                      class="w-12 h-12 border-dashed border border-gray-300 rounded-lg flex items-center text-gray-400 justify-center opacity-60 transition-all cursor-pointer"
                    >
                      <i class="ph ph-plus"></i>
                    </div>

                    <input type="file" disabled hidden />
                  </label>
                </div>
              </label>
            </div>
          </section>
        </div>
      </div>

      <button
        type="button"
        @click="handleUpdateSolicitation"
        :disabled="(solicitationData.roomType !== 'On-line' && solicitationData.status === 'Aprovada')"

        class="w-full py-2 flex items-center justify-center bg-blue-500 rounded-lg mt-10 text-white disabled:opacity-70 disabled:brightness-100 disabled:cursor-not-allowed disabled"
      >
        Salvar edição
      </button>

      <ComposableRoomDialog
        :displayDialog="displayDialog"
        :composableRooms="composableRooms"
        @handleCloseDialog="handleCloseDialog"
        @handleConfirm="handleConfirm"
      />
    </div>
  </Schema>
</template>

<script>
import { useToast } from 'vue-toastification';

import Schema from '../../components/Schema/index.vue';
import api from '../../lib/axios';

import Cookies from 'vue-cookies';
import { format } from 'date-fns';
import { messageSuccess } from '../../lib/toast';
import ComposableRoomDialog from '../../components/ComposableRoomDialog.vue';

import {
  useSolicitationService,
  useRoomLayoutsService,
  useRoomsService,
  useAppointmentService,
} from '../../services';

export default {
  name: 'ReservesView',

  setup() {
    const toast = useToast();
    return { toast };
  },

  components: {
    Schema,
    ComposableRoomDialog,
  },

  mounted() {
    this.token = Cookies.get('token');
    this.userId = Cookies.get('user_id');
    this.solicitationId = this.$route.params.id;

    this.getSolicitationId();
    this.getHistory();
    this.getAllMessages();
    this.getLocals();
    this.getUnits();
    this.getEquipments();
  },

  data() {
    return {
      token: '',
      userId: '',
      solicitationId: '',

      locals: [],
      units: [],
      roomLayouts: [],
      rooms: [],
      equipments: [],

      isActiveComunicationChat: false,
      status: '',
      solicitationData: {},
      equipmentsSelect: [],
      messages: [],
      history: [],
      message: '',

      recurrentData: [],

      isLoadingApprovedReserve: false,
      isLoadingSendingMessage: false,

      recurrentActive: false,
      recurrentApprovedActive: false,

      recurrentsApproved: [],
      userIdValue: '',
      userInfo: Cookies.get('user_info'),

      appointments: [],
      solicitationService: useSolicitationService(),
      displayDialog: false,
      composableRooms: null,
      roomLayoutsService: useRoomLayoutsService(),
      roomsService: useRoomsService(),
      appointmentService: useAppointmentService(),
      isComposable: false,
      composablePairRoomId: null,
      optionComposableSelected: false,
    };
  },

  computed: {
    isLoading: {
      set(value) {
        this.$store.commit('Default/CHANGE_ISLOADING', value);
      },
    },
  },

  methods: {
    handleLayoutChange(event) {
      const layoutFind = this.roomLayouts.find(
        (layout) => layout.name === event.target.value
      );

      this.solicitationData.roomLayout = layoutFind.name;
    },

    handleRoomChange(event) {
      const roomId = Number(event.target.value);
      const roomFind = this.rooms.find((room) => room.id === roomId);

      this.solicitationData.roomLayout = null;

      this.solicitationData.roomId = roomFind.id;
      this.roomLayouts = roomFind.getLayouts;
    },

    handleCloseDialog() {
      this.displayDialog = false;
      this.isComposable = true;
      this.optionComposableSelected = false;
      this.updateStatusSolicitation();
    },

    async handleOpenDialog() {
      this.isComposable = false;
      this.composablePairRoomId = null;
      this.optionComposableSelected = false;

      if (this.solicitationData.status === 'Aprovada') {
        if (this.solicitationData.roomId) {
          const result = await this.roomsService.getRoomById(
            this.solicitationData.roomId
          );

          if (result && result.composable) {
            const room2 = this.rooms.find(
              (room) => room.id === result.composablePair
            );

            this.composableRooms = {
              room1: result.name,
              room2: room2.name,
            };

            this.composablePairRoomId = result.composablePair;
            this.displayDialog = true;
          } else {
            await this.updateStatusSolicitation();
          }
        } else {
          await this.updateStatusSolicitation();
        }
      } else {
        await this.updateStatusSolicitation();
      }
    },

    handleConfirm() {
      this.displayDialog = false;
      this.isComposable = true;
      this.optionComposableSelected = true;
      this.updateStatusSolicitation();
    },

    async getSolicitationId() {
      const id = this.$route.params.id;

      const result = await this.solicitationService.getSolicitationId(id);
      this.solicitationData = result;

      this.rooms = result.getLocalId.Rooms.filter(room => room.active === true);

      this.rooms.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
      );

      this.roomLayouts = this.solicitationData.roomId
        ? this.rooms.find((room) => room.id === this.solicitationData.roomId)
            .getLayouts
        : [];

      if (result) {
        this.getAppointmentsDateForward(result.date);
      }

      if (result.recurrent === true) {
        const decoded = JSON.parse(result.recurrentPattern);
        this.solicitationData.recurrentPattern = decoded.recurrent;
      }

      this.getAppoitmentsBySolicitation(id);
    },

    getAppoitmentsBySolicitation(solicitationId) {
      api
        .get(`/appoitments/solicitation/${solicitationId}`, {
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          this.recurrentsApproved = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleDeleteRecurrentApproved(solicitationId) {
      api
        .delete(`/appointments/${solicitationId}`, {
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          messageSuccess(res.data.msg);
          this.getAppoitmentsBySolicitation(solicitationId);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getHistory() {
      const id = this.$route.params.id;

      api
        .get(`/solicitations/history/${id}`, {
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          const historyFormatted = res.data.map((item) => {
            const dateFormatted = format(
              new Date(item.createdAt),
              "dd/MM/yyyy 'às' HH:mm:ss"
            );

            const hist = {
              username: item.username,
              status: item.status,
              createdAt: dateFormatted,
            };
            return hist;
          });

          this.history = historyFormatted;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getAppointmentsDateForward(date) {
      this.appointmentService
        .getAppointmentsDateForward({ date: date })
        .then((appointments) => {
          if (this.solicitationData.roomType === 'On-line') {
            const filterNotInPerson = appointments.filter(
              (app) => app.getSolicitationId.roomType !== 'Presencial'
            );

            const filterNotInOnline = filterNotInPerson.filter(
              (app) => app.getSolicitationId.roomType !== 'On-line'
            );

            this.appointments = filterNotInOnline;
          } else {
            const filterNotOnline = appointments.filter(
              (app) => app.getSolicitationId.roomType !== 'On-line'
            );

            this.appointments = filterNotOnline;
          }
        });
    },

    async updateStatusSolicitation() {
      try {
        this.isLoading = true;

        const data = {
          solicitation: Number(this.solicitationId),
          status: this.solicitationData.status,
          updatedBy: Number(this.userId),
          unitId: Number(this.solicitationData.unitId),
          recurrent: Boolean(this.solicitationData.recurrent),
          roomId: this.solicitationData.roomId,
        };

        const dataUpdateAppointment = {
          solicitationId: Number(this.solicitationId),
          roomType: this.solicitationData.roomType,
          status: this.solicitationData.status,
          localId: this.solicitationData.localId,
          approvedBy: Number(this.userId),
          isComposable: this.isComposable,
          composablePairRoomId: this.composablePairRoomId,
          appointments: this.solicitationData.Appointments,
          optionComposableSelected: this.optionComposableSelected,
        };

        await this.appointmentService.updateStatus(dataUpdateAppointment);

        await this.solicitationService.updateStatusSolicitation(data);
        messageSuccess('Status da solicitação atualizado com sucesso!');
        this.$router.push({ path: '/gerenciamento/reservas' });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    connectEquipment(id) {
      const token = Cookies.get('token');
      const userId = Cookies.get('user_id');
      api
        .patch(
          `solicitation/${this.solicitationId}`,
          {
            mobileEquipments: {
              connect: [{ id: id }],
            },
            updatedBy: Number(userId),
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then(() => {
          api
            .get(`/solicitations/${this.solicitationId}`, {
              headers: {
                token: token,
              },
            })
            .then((res) => {
              this.solicitationData = res.data;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    disconnectEquipment(id) {
      const token = Cookies.get('token');
      const userId = Cookies.get('user_id');

      api
        .patch(
          `solicitation/${this.solicitationId}`,
          {
            mobileEquipments: {
              disconnect: [{ id: id }],
            },
            updatedBy: Number(userId),
          },
          {
            headers: {
              token: token,
            },
          }
        )
        .then(() => {
          api
            .get(`/solicitations/${this.solicitationId}`, {
              headers: {
                token: token,
              },
            })
            .then((res) => {
              this.solicitationData = res.data;
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getAllMessages() {
      const solicitationId = this.$route.params.id;

      api
        .get(`/messages/${solicitationId}`, {
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          this.messages = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleCreateNewMessage() {
      this.isLoadingSendingMessage = true;

      api
        .post(
          '/messages',
          {
            userId: Number(this.userId),
            message: this.message,
            solicitationId: Number(this.solicitationId),
            unitId: Number(this.solicitationData.unitId),
          },
          {
            headers: {
              token: this.token,
            },
          }
        )
        .then(() => {
          this.message = '';
          this.getAllMessages();
          this.isLoadingSendingMessage = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoadingSendingMessage = false;
        });
    },

    async handleUpdateSolicitation() {
      this.isLoading = true;

      let data = {
        eventName: this.solicitationData.eventName,
        updatedBy: Number(this.userInfo.id),
        updatedByName: this.userInfo.name,
        eventDescription: this.solicitationData.eventDescription,
        numberOfParticipants: Number(
          this.solicitationData.numberOfParticipants
        ),
        numberOfSpeechers: Number(this.solicitationData.numberOfSpeechers),
        transmission: this.solicitationData.transmission,
        typeOfTransmission: this.solicitationData.typeOfTransmission,
        transmissionDescription: this.solicitationData.transmissionDescription,
        socialNetworkText: this.solicitationData.socialNetworkText,
        programmingText: this.solicitationData.programmingText,
        typeOfLink: this.solicitationData.typeOfLink,
        accessesPrevision: Number(this.solicitationData.accessesPrevision),
        previousPreparation: this.solicitationData.previousPreparation,
        preparationStartDay: this.solicitationData.preparationStartDay,
        preparationStartInitial: this.solicitationData.preparationStartInitial,
        preparationStartFinal: this.solicitationData.preparationStartFinal,
        preparationEndDay: this.solicitationData.preparationEndDay,
        preparationEndInitial: this.solicitationData.preparationEndInitial,
        preparationEndFinal: this.solicitationData.preparationEndFinal,
        roomId: this.solicitationData.roomId,
        zoomResources: this.solicitationData.zoomResources,
        date: this.solicitationData.date,
       
        costCenter: this.solicitationData.costCenter,
        recurrent: this.solicitationData.recurrent,
        equipmentsDescription: this.solicitationData.equipmentsDescription,
        recurrentPattern: this.solicitationData.recurrent
          ? JSON.stringify({
              msg: 'Frequência',
              recurrent: this.solicitationData.recurrentPattern,
            })
          : null,
        board: this.solicitationData.board,
        manager: this.solicitationData.manager,
        localId: Number(this.solicitationData.localId),
        unitId: Number(this.solicitationData.unitId),
        roomLayout: this.solicitationData.roomLayout,
        appointments: this.solicitationData.Appointments,
      };

      if(!this.solicitationData.recurrent) {
        data = {...data,  startTime: this.solicitationData.startTime,
          finalTime: this.solicitationData.finalTime}
      }

      await this.solicitationService
        .updateSolicitation(this.solicitationData.id, data)
        .then(() => {
          messageSuccess('Solicitação atualizada com sucesso');
          this.$router.push({ path: '/gerenciamento/reservas' });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getLocals() {
      api
        .get('/locals', {
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          this.locals = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getUnits() {
      api
        .get('/units', {
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          this.units = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getEquipments() {
      api
        .get('/equipments', {
          headers: {
            token: this.token,
          },
        })
        .then((res) => {
          this.equipments = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    handleDeleteRecurrent(id) {
      const filterRecurrent = this.recurrentData.filter(
        (item) => item.id !== id
      );

      this.recurrentData = filterRecurrent;
      this.solicitationData.recurrentPattern = JSON.stringify(filterRecurrent);

      this.handleUpdateSolicitation();
    },

    goToSolicitations() {
      this.isActiveScreenEditSolicitation =
        !this.isActiveScreenEditSolicitation;
      this.$router.push({ path: '/gerenciamento/reservas' });
    },
  },
};
</script>
