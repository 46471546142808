import api from '../lib/axios';
import Cookies from 'vue-cookies';

export class LocalsService {
  constructor() {
    this.token = Cookies.get('token');
  }

  async getLocals(showAll = false) {
    try {
      const response = await api.get(`/locals?showAll=${showAll}`, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching locals:', error);
    }
  }

  async getLocalsById(localId) {
    try {
      const response = await api.get(`/locals/${localId}`, {
        headers: {
          token: this.token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching locals:', error);
    }
  }

  async verifyLocalWorkingTime(payload) {
    try {
      const response = await api.post(
        '/locals/verify-working-time-local',
        payload,
        {
          headers: {
            token: this.token,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching locals:', error);
    }
  }
}

export const useLocalsService = () => new LocalsService();
