import api from '../../lib/axios';
import Cookies from 'vue-cookies';

const Units = {
  namespaced: true,
  state: {
    unitsdata: [],
    isLoading: true,
  },
  mutations: {
    GET_DATA_UNITS(state, payload) {
      state.unitsdata = payload;
    },
  },
  actions: {
    getUnitsByData({ commit }, {address, showAll = false}) {
      const token = Cookies.get('token');

      api
        .get(`/units?showAll=${showAll}`, {
          headers: {
            token: token,
          },
        })
        .then((res) => {
          commit('GET_DATA_UNITS', res.data);
          this.state.Units.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

export default Units;
