<template>
  <DataTable
    :value="sortableAppointments"
    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
    paginator
    :rows="15"
    dataKey="id"
    ref="dataTableRef"
    :rowsPerPageOptions="[15, 20, 30, 50]"
    currentPageReportTemplate="{first} de {last} de {totalRecords}"
    v-model:filters="filters"
    filterDisplay="menu"
   sortMode="multiple"
    :multiSortMeta="[{ field: 'roomName', order: 1 }]"
    :globalFilterFields="[
      'roomName',
      'eventName',
      'dateFormatted',
      'localName',
      'solicitationId',
    ]"
  >
    <template #groupheader="slotProps">
          <span class="align-middle ml-2 font-bold leading-normal">{{ slotProps.data.roomName }}</span>
    </template>
    <template #header >
      <span class="p-input-icon-left">
        <i class="pi pi-search pt-1" />
        <input
          v-model="filters['global'].value"
          placeholder="Pesquisa global"
          class="border-2 border-gray-300 p-3 pl-10 rounded-lg w-full text-sm mt-2"
        />
      </span>
    </template>
  
    <Column field="roomName" header="Sala" sortable sortField="roomName">
      <template #body="{ data }">
        <span>{{ data.roomName }}</span>
      </template>
      <template #filter="{}">
        <input
          type="text"
          class="h-10 border-2 border-gray-300 px-3 rounded-lg"
          v-model="filterRoomName"
          placeholder="Pesquisar por sala"
        />
        <div class="flex justify-between">
          <button
            @click="handleFilterClear('filterRoomName')"
            class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
          >
            Limpar
          </button>
          <button
            @click="handleFilter"
            :disabled="!filterRoomName"
            class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
          >
            Aplicar
          </button>
        </div>
      </template>
    </Column>
    
    <Column field="eventName" header="Nome do evento" sortable>
      <template #filter="{}">
        <input
          type="text"
          class="h-10 border-2 border-gray-300 px-3 rounded-lg"
          v-model="filterEventName"
          placeholder="Pesquisar por evento"
        />
        <div class="flex justify-between">
          <button
            @click="handleFilterClear('filterEventName')"
            class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
          >
            Limpar
          </button>
          <button
            @click="handleFilter"
            :disabled="!filterEventName"
            class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
          >
            Aplicar
          </button>
        </div>
      </template>
    </Column>
    <Column field="localName" header="Local" sortable>
      <template #filter="{}">
        <input
          type="text"
          class="h-10 border-2 border-gray-300 px-3 rounded-lg"
          v-model="filterLocalName"
          placeholder="Pesquisar por local"
        />
        <div class="flex justify-between">
          <button
            @click="handleFilterClear('filterLocalName')"
            class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
          >
            Limpar
          </button>
          <button
            @click="handleFilter"
            :disabled="!filterLocalName"
            class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
          >
            Aplicar
          </button>
        </div>
      </template>
    </Column>
    <Column 
  field="dateFormatted" 
  header="Data" 
   sortable
   sortField="date"
>
  <template #filter="{}">
    <input
      type="text"
      class="h-10 border-2 border-gray-300 px-3 rounded-lg"
      v-model="filterDateFormatted"
      placeholder="Pesquisar por data"
    />
    <div class="flex justify-between">
      <button
        @click="handleFilterClear('filterDateFormatted')"
        class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
      >
        Limpar
      </button>
      <button
        @click="handleFilter"
        :disabled="!filterDateFormatted"
        class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
      >
        Aplicar
      </button>
    </div>
  </template>
</Column>

    <Column field="time" header="Hora" sortable>
      <template #filter="{}">
        <input
          type="text"
          class="h-10 border-2 border-gray-300 px-3 rounded-lg"
          v-model="filterTime"
          placeholder="Pesquisar por hora"
        />
        <div class="flex justify-between">
          <button
            @click="handleFilterClear('filterTime')"
            class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
          >
            Limpar
          </button>
          <button
            @click="handleFilter"
            class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
          >
            Aplicar
          </button>
        </div>
      </template>
    </Column>
    <Column field="solicitationId" header="Solicitação" sortable>
 
      <template #filter="{}">
        <input
          type="number"
          class="h-10 border-2 border-gray-300 px-3 rounded-lg"
          v-model="filterSolicitationId"
          placeholder="Pesquisar por solicitação"
        />
        <div class="flex justify-between">
          <button
            @click="handleFilterClear('filterSolicitationId')"
            class="text-blue-500 px-5 text-sm flex items-center gap-2 py-2 mt-5 hover:brightness-90 transition-all"
          >
            Limpar
          </button>
          <button
            @click="handleFilter"
            class="bg-blue-500 text-white px-5 text-sm flex items-center mt-5 gap-2 py-2 rounded-md hover:brightness-90 transition-all"
          >
            Aplicar
          </button>
        </div>
      </template>
      <template #body="{ data }">
        <span
          class="cursor-pointer underline"
          @click="handleShowSolicitation(data.solicitationId)"
          >#{{ data.solicitationId }}</span
        >
        <i
          v-if="data.recurrent"
          :style="{ backgroundColor: '#37CBCF' }"
          class="ph ph-repeat p-1.5 ml-5 rounded-full text-white"
          v-tooltip.bottom="'Evento recorrente'"
        ></i>
      </template>
    </Column>        
    <template #empty> Nenhum registro encontrado </template>
  </DataTable>
</template>
<style>
.p-rowgroup-header > td {
  border: none !important;
}
</style>
<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';
import { ref } from 'vue';

export default {
  name: 'DataTableAppointments',

  components: {
    DataTable,
    Column,
  },

  props: {
    appointments: Array,
    
  },

  created() {
    this.initFilters();
  },

  mounted() {
    this.handleChangeElement();
    this.sortableAppointments = this.appointments.map(appointment => {
    // Converter a data no formato 'DD/MM/YYYY' para 'YYYY/MM/DD'
    const [day, month, year] = appointment.dateFormatted.split('/');
    const date = `${year}/${month}/${day}`;
    
    // Retorna o objeto com a nova propriedade 'date'
    return { 
      ...appointment, 
      date 
    };
  });
  },

  updated() {
    // this.initFilters();
  },

  data() {
    return {
      filters: null,
      expandedRowGroups: null,
      dataTableRef: ref(),
      sortableAppointments: [],
    };
  },

  methods: {
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        roomName: { value: null, matchMode: null },
        eventName: { value: null, matchMode: null },
        localName: { value: null, matchMode: null },
        dateFormatted: { value: null, matchMode: null },
        time: { value: null, matchMode: null },
        solicitationId: { value: null, matchMode: null },
      };
    },

    handleShowSolicitation(solicitationId) {
      this.$router.push(`/gerenciamento/reservas/${solicitationId}`);
    },

    handleChangeElement() {
      const getElement1 = document.getElementsByClassName(
        'pi pi-filter-icon pi-filter'
      );

      const getElement2 = document.getElementsByClassName('pi pi-filter-fill');

      if (this.filterRoomName.length && getElement1.length) {
        getElement1[0].className = 'pi pi-filter-fill';
        return;
      }

      if (!this.filterRoomName.length && getElement2.length) {
        getElement2[0].className = 'pi pi-filter-icon pi-filter';
        return;
      }

      if (this.filterEventName.length && getElement1.length) {
        getElement1[1].className = 'pi pi-filter-fill';
        return;
      }

      if (!this.filterEventName.length && getElement2.length) {
        getElement2[1].className = 'pi pi-filter-icon pi-filter';
        return;
      }

      if (this.filterLocalName.length && getElement1.length) {
        getElement1[2].className = 'pi pi-filter-fill';
        return;
      }

      if (!this.filterLocalName.length && getElement2.length) {
        getElement2[2].className = 'pi pi-filter-icon pi-filter';
        return;
      }

      if (this.filterDateFormatted.length && getElement1.length) {
        getElement1[3].className = 'pi pi-filter-fill';
        return;
      }

      if (!this.filterDateFormatted.length && getElement2.length) {
        getElement2[3].className = 'pi pi-filter-icon pi-filter';
        return;
      }

      if (this.filterTime.length && getElement1.length) {
        getElement1[4].className = 'pi pi-filter-fill';
        return;
      }

      if (!this.filterTime.length && getElement2.length) {
        getElement2[4].className = 'pi pi-filter-icon pi-filter';
        return;
      }

      if (this.filterSolicitationId !== '' && getElement1.length) {
        getElement1[5].className = 'pi pi-filter-fill';
        return;
      }

      if (this.filterSolicitationId === '' && getElement2.length) {
        getElement2[5].className = 'pi pi-filter-icon pi-filter';
      }
    },

    handleFilter() {
      this.$emit('handleFilter');
    },

    handleFilterClear(field) {
      if (field === 'filterRoomName') {
        this.filterRoomName = '';
      } else if (field === 'filterEventName') {
        this.filterEventName = '';
      } else if (field === 'filterLocalName') {
        this.filterLocalName = '';
      } else if (field === 'filterDateFormatted') {
        this.filterDateFormatted = '';
      } else if (field === 'filterTime') {
        this.filterTime = '';
      } else if (field === 'filterSolicitationId') {
        this.filterSolicitationId = '';
      }

      this.$emit('handleFilter');
    },
  },

  computed: {
    filterRoomName: {
      get() {
        return this.$store.state.AdminScreen.filterRoomName;
      },
      set(value) {
        this.$store.commit('AdminScreen/SET_FILTER_ROOM_NAME', value);
      },
    },
    filterEventName: {
      get() {
        return this.$store.state.AdminScreen.filterEventName;
      },
      set(value) {
        this.$store.commit('AdminScreen/SET_FILTER_EVENT_NAME', value);
      },
    },
    filterLocalName: {
      get() {
        return this.$store.state.AdminScreen.filterLocalName;
      },
      set(value) {
        this.$store.commit('AdminScreen/SET_FILTER_LOCAL_NAME', value);
      },
    },
    filterDateFormatted: {
      get() {
        return this.$store.state.AdminScreen.filterDateFormatted;
      },

      set(value) {
        this.$store.commit('AdminScreen/SET_FILTER_DATE_FORMATTED', value);
      },
    },
    filterTime: {
      get() {
        return this.$store.state.AdminScreen.filterTime;
      },
      set(value) {
        this.$store.commit('AdminScreen/SET_FILTER_TIME', value);
      },
    },
    filterSolicitationId: {
      get() {
        return this.$store.state.AdminScreen.filterSolicitationId;
      },
      set(value) {
        this.$store.commit('AdminScreen/SET_FILTER_SOLICITATION_ID', value);
      },
    },
  },
};
</script>
